import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Space } from "components/Common/Space";
import { TrueOrFalseCarousel } from "./Carousel";
import {
  TTrueOrFalseExercise,
  TTrueOrFalseExercisePayload,
} from "types/app/exercises";
import "./TrueOrFalse.styles.scss";

type TTrueOrFalseProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TTrueOrFalseExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const TrueOrFalse: React.FC<TTrueOrFalseProps> = (props) => {
  const { chapterId, exercise, prefix } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TTrueOrFalseExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      <TrueOrFalseCarousel answers={exercise.payload.data.answers} />
    </Space>
  );
};
