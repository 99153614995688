import { useForm } from "react-hook-form";
import {
  useAddExercise,
  useAddHomeworkExercise,
  useEditExercise,
  useEditHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor } from "hooks/redux/useExerciseConstructor";
import { ExerciseConstructorContent, NoteExerciseContent } from "content";
import { Textarea } from "components/Interactive/Textarea";
import { Select } from "components/Interactive/Select";
import { Checkbox } from "components/Interactive/Checkbox";
import { Space } from "components/Common/Space";
import { ExerciseConfigForm } from "../../ExerciseConfigForm";
import { logError, logSuccess } from "utils/notifications";
import { NOTE_EXERCISE_TYPE_OPTIONS } from "utils/options";
import {
  TAddExercise,
  TEditExercise,
  TExercisePayload,
} from "types/api/exercise";
import { EExerciseType, TNoteExercisePayload } from "types/app/exercises";
import { TNotesExerciseForm } from "./NotesExerciseForm.types";
import "./NotesExerciseForm.styles.scss";

export const NotesExerciseForm: React.FC = () => {
  const {
    courseId,
    lessonId,
    chapterId,
    homeworkId,
    editExercise,
    isEditMode,
    constructorMode,
    closeAll,
  } = useExerciseConstructor();

  const { mutate: editExerciseHandler } = useEditExercise<TNoteExercisePayload>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: editHomeworkExerciseHandler } =
    useEditHomeworkExercise<TNoteExercisePayload>(
      courseId,
      lessonId,
      homeworkId
    );
  const { mutate: addExercise } = useAddExercise<TNoteExercisePayload, never>(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: addHomeworkExercise } = useAddHomeworkExercise<
    TNoteExercisePayload,
    never
  >(courseId, lessonId, homeworkId);

  const [initialNoteType] = NOTE_EXERCISE_TYPE_OPTIONS;
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<TNotesExerciseForm>({
    mode: "onBlur",
    defaultValues: {
      noteType: initialNoteType,
      isStudentVisible: false,
      content: "",
    },
  });

  const successCallback = () => {
    closeAll();
    logSuccess(ExerciseConstructorContent.Notification.Add.SUCCESS);
    reset({
      noteType: initialNoteType,
      isStudentVisible: false,
      content: "",
    });
  };

  const submitLessonExercise = (
    body: TAddExercise<TNoteExercisePayload, never>
  ) => {
    addExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitHomeworkExercise = (
    body: TAddExercise<TNoteExercisePayload, never>
  ) => {
    addHomeworkExercise(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditLessonExercise = (
    body: TEditExercise<TNoteExercisePayload>
  ) => {
    editExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const submitEditHomeworkExercise = (
    body: TEditExercise<TNoteExercisePayload>
  ) => {
    editHomeworkExerciseHandler(body, {
      onSuccess: successCallback,
    });
  };

  const addExerciseSubmitHandler = (formData: TNotesExerciseForm) => {
    const isExerciseParamsInvalid =
      !courseId || !lessonId || !(chapterId || homeworkId);
    if (isExerciseParamsInvalid) {
      logError({
        message: ExerciseConstructorContent.Notification.Add.ERROR,
      });
      return;
    }

    const addBody: TAddExercise<TNoteExercisePayload, never> = {
      type: EExerciseType.TEACHER_NOTES,
      payload: {
        data: {
          noteType: formData.noteType.value,
          isStudentVisible: formData.isStudentVisible,
          content: formData.content,
        },
      },
    };

    if (!isEditMode && constructorMode === "lesson") {
      return submitLessonExercise(addBody);
    }

    if (!isEditMode && constructorMode === "homework") {
      return submitHomeworkExercise(addBody);
    }

    if (isEditMode && editExercise) {
      const editBody: {
        id: number;
        type: EExerciseType;
        payload: Omit<TExercisePayload<TNoteExercisePayload>, "text">;
      } = {
        id: editExercise.id,
        ...addBody,
      };

      if (constructorMode === "lesson") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return submitEditLessonExercise(editBody);
      }

      if (constructorMode === "homework") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return submitEditHomeworkExercise(editBody);
      }
    }
  };

  return (
    <ExerciseConfigForm
      onSubmit={handleSubmit(addExerciseSubmitHandler)}
      isEditMode={isEditMode}
    >
      <Space direction="vertical" justify="start" size="medium" fullWidth>
        <Select<TNotesExerciseForm>
          name="noteType"
          id="note-exercise-type"
          className="nb-exercise-constructor-note-type"
          placeholder={NoteExerciseContent.Add.Input.Type.PLACEHOLDER}
          label={NoteExerciseContent.Add.Input.Type.LABEL}
          options={NOTE_EXERCISE_TYPE_OPTIONS}
          control={control}
          errors={errors}
          required
          fullWidth
        />
        <Checkbox<TNotesExerciseForm>
          name="isStudentVisible"
          id="note-exercise-student-visible"
          className="nb-exercise-constructor-note-visible"
          control={control}
          label={NoteExerciseContent.Add.Input.Visible.LABEL}
          errors={errors}
          fullWidth
        />
        <Textarea<TNotesExerciseForm>
          name="content"
          id="note-exercise-content"
          className="nb-exercise-constructor-note-content"
          label={NoteExerciseContent.Add.Input.Content.LABEL}
          placeholder={NoteExerciseContent.Add.Input.Content.PLACEHOLDER}
          register={register}
          errors={errors}
          required
          fullWidth
        />
      </Space>
    </ExerciseConfigForm>
  );
};
