import { NavigationContent } from "content";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { ReactComponent as Study } from "assets/icons/study.svg";
import { ReactComponent as Materials } from "assets/icons/materials.svg";
import { ReactComponent as Finance } from "assets/icons/finance.svg";
import { Route } from "constants/routes";
import { TNavigation } from "types/app/navigation";
import { EUserRole } from "types/api/role";

export const SITE_NAVIGATION: TNavigation[] = [
  {
    path: Route.App.Schedule.ROOT.path,
    label: NavigationContent.Main.Schedule,
    icon: <Calendar />,
    access: [EUserRole.ADMIN, EUserRole.STUDENT, EUserRole.TEACHER],
    disabled: false,
  },
  {
    path: Route.App.Study.ROOT.path,
    label: NavigationContent.Main.Study,
    icon: <Study />,
    access: [EUserRole.ADMIN, EUserRole.TEACHER],
    disabled: false,
  },
  {
    path: Route.App.Progress.ROOT.path,
    label: NavigationContent.Main.Progress,
    icon: <Study />,
    access: [EUserRole.ADMIN],
    disabled: true,
  },
  {
    path: Route.App.Materials.ROOT.path,
    label: NavigationContent.Main.Materials,
    icon: <Materials />,
    access: [EUserRole.ADMIN, EUserRole.TEACHER],
    disabled: false,
  },
  {
    path: Route.App.Study.STUDENT_CLASSROOM.path,
    label: NavigationContent.Main.Materials,
    icon: <Materials />,
    access: [EUserRole.STUDENT],
    disabled: false,
  },
  {
    path: Route.App.Finance.ROOT.path,
    label: NavigationContent.Main.Finance,
    icon: <Finance />,
    access: [EUserRole.ADMIN],
    disabled: true,
  },
];
