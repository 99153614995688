import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { DndContainer } from "./DndContainer";
import {
  TSortWordsExercise,
  TSortWordsExercisePayload,
} from "types/app/exercises";
import "./SortWords.styles.scss";

type TSortWordsProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TSortWordsExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const SortWords: React.FC<TSortWordsProps> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TSortWordsExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      <DndContainer exerciseData={exercise.payload.data} />
    </Space>
  );
};
