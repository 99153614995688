import { useState } from "react";
import { ScheduleControls } from "./components/ScheduleControls";
import { ScheduleDashboard } from "./components/ScheduleDashboard";
import "./Schedule.styles.scss";

export const Schedule: React.FC = () => {
  const [selectedStudentId, setSelectedStudentId] = useState<number>();
  const [selectedTeacherId, setSelectedTeacherId] = useState<number>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [visibleMonths, setVisibleMonths] = useState<string>("");

  const teacherChangeHandler = (teacherId?: number) => {
    setSelectedTeacherId(teacherId);
  };

  const studentChangeHandler = (studentId?: number) => {
    setSelectedStudentId(studentId);
  };

  const groupChangeHandler = (groupId?: number) => {
    setSelectedGroupId(groupId);
  };

  const updateVisibleMonthsHandler = (months: string) => {
    setVisibleMonths(months);
  };

  return (
    <>
      <ScheduleControls
        onTeacherChange={teacherChangeHandler}
        onStudentChange={studentChangeHandler}
        onGroupChange={groupChangeHandler}
        title={visibleMonths}
      />
      <ScheduleDashboard
        teacherId={selectedTeacherId}
        studentId={selectedStudentId}
        groupId={selectedGroupId}
        onUpdateVisibleMonths={updateVisibleMonthsHandler}
      />
    </>
  );
};
