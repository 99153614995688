import { useDetachCourseLessonFromStudent } from "hooks/api/courses/useDetachCourseLessonFromStudent";
import { useRole } from "hooks/common";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StudentClassroomContent as Content,
  CourseDetailsContent,
} from "content";
import { useBreadcrumbs } from "hooks/redux";
import { useStudentById } from "hooks/api/students";
import {
  useAddCourseLessonToStudent,
  useCourseById,
  useCourseLessons,
} from "hooks/api/courses";
import { useAddLessonToStudentColumns } from "hooks/table/useAddLessonToStudentColumns";
import { Table } from "components/Table";
import { Empty } from "components/Common/Empty";
import { CourseHero } from "./components/CourseHero";
import { Route } from "constants/routes";
import { TStudentCourseLesson } from "types/api/course";
import "./StudentClassroomCourse.styles.scss";

export const StudentClassroomCourse: React.FC = () => {
  const { studentId, courseId } = useParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { activeUser } = useActiveUser();
  const { isUserStudent } = useRole();

  const { setPageName, resetPageName } = useBreadcrumbs();
  const {
    data: course,
    isLoading: isCourseLoading,
    error,
  } = useCourseById(courseId);
  const { data: student } = useStudentById(studentId);
  const { data: courseLessonsData, isLoading: isLessonsLoading } =
    useCourseLessons(courseId, {
      pageNumber,
      pageSize: 99999,
      studentId: isUserStudent ? activeUser?.studentId : null,
    });
  const { data: studentCourseLessonsData, isLoading: isStudentLessonsLoading } =
    useCourseLessons(courseId, {
      pageNumber,
      studentId,
    });
  const { mutate: addLessonToStudent } = useAddCourseLessonToStudent();
  const { mutate: detachCourseLessonFromStudent } =
    useDetachCourseLessonFromStudent();

  const courseLessons: TStudentCourseLesson[] = useMemo(
    () =>
      (courseLessonsData?.content || []).map((lesson) => ({
        ...lesson,
        isPinned:
          studentCourseLessonsData?.content?.some(
            (studentLesson) => studentLesson.id === lesson.id
          ) || false,
      })),
    [courseLessonsData?.content, studentCourseLessonsData?.content]
  );
  const isEmpty: boolean = useMemo(
    () => courseLessons.length === 0,
    [courseLessons]
  );
  const isLoading: boolean = useMemo(
    () => isLessonsLoading || isStudentLessonsLoading,
    [isLessonsLoading, isStudentLessonsLoading]
  );

  const pinLessonClickHandler = (courseLessonId: number) => {
    if (!studentId) return;

    addLessonToStudent({
      studentId: Number(studentId),
      courseId: courseId,
      lessonId: courseLessonId,
    });
  };

  const unpinLessonClickHandler = (courseLessonId: number) => {
    if (!studentId) return;

    detachCourseLessonFromStudent({
      studentId: Number(studentId),
      courseId: courseId,
      lessonId: courseLessonId,
    });
  };

  const viewLessonClickHandler = (courseLessonId: number) => {
    if (!studentId || !courseId) return;
    navigate(
      Route.App.Study.STUDENT_CLASSROOM_COURSE_LESSON.construct(
        studentId,
        courseId,
        courseLessonId.toString()
      )
    );
  };

  const columns = useAddLessonToStudentColumns(courseId, {
    onPinClick: pinLessonClickHandler,
    onUnPinClick: unpinLessonClickHandler,
    onViewClick: viewLessonClickHandler,
  });

  useEffect(() => {
    if (student?.person) {
      const fullName = `${Content.Name} ${student.person.firstName} ${student.person.lastName}`;
      setPageName(fullName);
    }
    return () => resetPageName();
  }, [student?.person, setPageName, resetPageName]);

  useEffect(() => {
    // TODO: handler properly not found resource error
    if (error) {
      navigate(Route.App.Materials.ROOT.path);
    }
  }, [error, navigate]);

  return (
    <>
      <CourseHero course={course} loading={isCourseLoading} />
      {isEmpty && !isLoading ? (
        <Empty text={CourseDetailsContent.EmptyForStudent} />
      ) : (
        <Table<TStudentCourseLesson>
          data={courseLessons}
          columns={columns}
          loading={isLoading}
          totalItems={courseLessonsData?.totalElements}
          onPageChange={setPageNumber}
          hideHeader
          hideFooter
        />
      )}
    </>
  );
};
