import {
  AdminContent,
  CourseDetailsContent,
  FinanceContent,
  GroupDetailsContent,
  GroupsContent,
  LandingDashboardContent,
  LessonDetailsContent,
  LoginContent,
  MaterialsContent,
  ProgressContent,
  ReportsContent,
  RequestsContent,
  ScheduleContent,
  StudentClassroomContent,
  StudentsContent,
  StudyContent,
  SupportContent,
  TeachersContent,
  UserSettingsContent,
} from "content";
import { TUrlParam } from "types/utils";
import { TAppRoutes } from "types/app/route";

export const Route: TAppRoutes = {
  Auth: {
    Login: {
      ROOT: {
        path: "/login",
        name: LoginContent.Admin.Title,
        isEndPoint: true,
        isDynamicName: false,
        key: "login",
        emoji: "",
        construct: () => "/login",
      },
    },
    ForgetPassword: {
      ROOT: {
        path: "/forget-password",
        name: LoginContent.Admin.Title,
        isEndPoint: true,
        isDynamicName: false,
        key: "forget-password",
        emoji: "",
        construct: () => "/forget-password",
      },
    },
    RequestResetPassword: {
      ROOT: {
        path: "/request-reset-password",
        name: LoginContent.Admin.Title,
        isEndPoint: true,
        isDynamicName: false,
        key: "request-reset-password",
        emoji: "",
        construct: () => "/request-reset-password",
      },
    },
  },
  App: {
    Study: {
      ROOT: {
        path: "/study",
        name: StudyContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study",
        emoji: StudyContent.Emoji,
        construct: () => "/study",
      },
      STUDENTS: {
        path: "/study/students",
        name: StudentsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study-students",
        emoji: StudyContent.Emoji,
        construct: () => "/study/students",
      },
      STUDENT_CLASSROOM: {
        path: "/study/students/:studentId/classroom",
        name: StudentClassroomContent.Name,
        isEndPoint: true,
        isDynamicName: true,
        key: "study-student-classroom",
        emoji: StudyContent.Emoji,
        construct: (studentId: TUrlParam) =>
          `/study/students/${studentId}/classroom`,
      },
      STUDENT_CLASSROOM_COURSE: {
        path: "/study/students/:studentId/classroom/:courseId",
        name: StudentClassroomContent.Name,
        isEndPoint: true,
        isDynamicName: true,
        key: "study-student-classroom-course",
        emoji: StudyContent.Emoji,
        construct: (studentId: TUrlParam, courseId: TUrlParam) =>
          `/study/students/${studentId}/classroom/${courseId}`,
      },
      STUDENT_CLASSROOM_COURSE_LESSON: {
        path: "/study/students/:studentId/classroom/:courseId/lesson/:lessonId",
        name: StudentClassroomContent.Name,
        isEndPoint: true,
        isDynamicName: true,
        key: "study-student-classroom-course-lesson",
        emoji: StudyContent.Emoji,
        construct: (
          studentId: TUrlParam,
          courseId: TUrlParam,
          lessonId: TUrlParam
        ) =>
          `/study/students/${studentId}/classroom/${courseId}/lesson/${lessonId}`,
      },
      GROUPS: {
        path: "/study/groups",
        name: GroupsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study-groups",
        emoji: StudyContent.Emoji,
        construct: () => "/study/groups",
      },
      GROUP_DETAILS: {
        path: "/study/groups/:id",
        name: GroupDetailsContent.Name,
        isEndPoint: true,
        isDynamicName: true,
        key: "study-group-details",
        emoji: StudyContent.Emoji,
        construct: (id: TUrlParam) => `/study/groups/${id}`,
      },
      TEACHERS: {
        path: "/study/teachers",
        name: TeachersContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study-teachers",
        emoji: StudyContent.Emoji,
        construct: () => "/study/teachers",
      },
      REQUESTS: {
        path: "/study/requests",
        name: RequestsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study-requests",
        emoji: StudyContent.Emoji,
        construct: () => "/study/requests",
      },
      REPORTS: {
        path: "/study/reports",
        name: ReportsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "study-reports",
        emoji: StudyContent.Emoji,
        construct: () => "/study/reports",
      },
    },
    Progress: {
      ROOT: {
        path: "/progress",
        name: ProgressContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "progress",
        emoji: ProgressContent.Emoji,
        construct: () => "/progress",
      },
    },
    Schedule: {
      ROOT: {
        path: "/schedule",
        name: ScheduleContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "schedule",
        emoji: ScheduleContent.Emoji,
        construct: () => "/schedule",
      },
    },
    Materials: {
      ROOT: {
        path: "/materials",
        name: MaterialsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "materials",
        emoji: MaterialsContent.Emoji,
        construct: () => "/materials",
      },
      COURSE_DETAILS: {
        path: "/materials/course/:courseId",
        name: CourseDetailsContent.Name,
        isEndPoint: true,
        isDynamicName: true,
        key: "materials-course-details",
        emoji: MaterialsContent.Emoji,
        construct: (courseId: TUrlParam) => `/materials/course/${courseId}`,
      },
      LESSON_DETAILS: {
        path: "/materials/course/:courseId/lesson/:lessonId",
        name: LessonDetailsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "materials-course-lesson-details",
        emoji: MaterialsContent.Emoji,
        construct: (courseId: TUrlParam, lessonId: TUrlParam) =>
          `/materials/course/${courseId}/lesson/${lessonId}`,
      },
    },
    Finance: {
      ROOT: {
        path: "/finance",
        name: FinanceContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "finance",
        emoji: FinanceContent.Emoji,
        construct: () => "/finance",
      },
    },
    Support: {
      ROOT: {
        path: "/support",
        name: SupportContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "support",
        emoji: SupportContent.Emoji,
        construct: () => "/support",
      },
    },
    User: {
      SETTINGS: {
        path: "/user/settings",
        name: UserSettingsContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "user-settings",
        emoji: UserSettingsContent.Emoji,
        construct: () => "/user/settings",
      },
    },
    Admin: {
      ROOT: {
        path: "/admin",
        name: AdminContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "admin-root",
        emoji: AdminContent.Emoji,
        construct: () => "/admin",
      },
      LANDING: {
        path: "/admin/landing",
        name: LandingDashboardContent.Name,
        isEndPoint: true,
        isDynamicName: false,
        key: "admin-landing",
        emoji: LandingDashboardContent.Emoji,
        construct: () => "/admin/landing",
      },
    },
  },
} as const;
