export enum ELanguageLevel {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  "B1+" = "B1_PLUS",
  B2 = "B2",
  "B2+" = "B2_PLUS",
  C1 = "C1",
  "C1+" = "C1_PLUS",
  C2 = "C2",
}
