import { Heading } from "components/Typography/Heading";
import { Route } from "constants/routes";
import { LoginContent } from "content";
import { Link } from "react-router-dom";
import { RequestResetPasswordForm } from "../RequestResetPasswordForm";
import "./RequestResetPasswordCard.styles.scss";

export const RequestResetPasswordCard: React.FC = () => {
  return (
    <div className="nb-page-component-request-reset-password-card">
      <Link
        className={"nb-page-component-request-reset-password-back-button"}
        to={Route.Auth.Login.ROOT.path}
      >
        Повернутися назад
      </Link>
      <Heading className="nb-page-component-login-card-title">
        {LoginContent.RequestResetPassword.Admin.Title}
      </Heading>
      <Heading level={2} className="nb-page-component-login-card-text">
        {LoginContent.RequestResetPassword.Admin.Text}
      </Heading>
      <RequestResetPasswordForm />
    </div>
  );
};
