export enum EExerciseType {
  ADD_TO_DICTIONARY = "ADD_TO_DICTIONARY",
  BUTTON = "BUTTON",
  TEST = "TEST",
  CHOOSE_RIGHT_WORD_FORM = "CHOOSE_RIGHT_WORD_FORM",
  WORDS_COMPARING = "WORDS_COMPARING",
  MAKE_WORD_FROM_LETTERS = "MAKE_WORD_FROM_LETTERS",
  COMPARE_IMAGE_WITH_DESCRIPTION = "COMPARE_IMAGE_WITH_DESCRIPTION",
  DESCRIBE_AUDIO = "DESCRIBE_AUDIO",
  GIF = "GIF",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  MOVE_WORDS_INTO_GAPS = "MOVE_WORDS_INTO_GAPS",
  TEACHER_NOTES = "TEACHER_NOTES",
  RECORD_AUDIO = "RECORD_AUDIO",
  SENTENCE_ORDER = "SENTENCE_ORDER",
  SORT_WORDS = "SORT_WORDS",
  TRUE_OR_FALSE = "TRUE_OR_FALSE",
  INPUT_WORDS_INTO_GAPS = "INPUT_WORDS_INTO_GAPS",
  ESSAY = "ESSAY",
}

export type TExerciseIdentifier = string | number;

export type TExerciseTimerValue = 30 | 45 | 60 | 75 | 90;

export type TExerciseTimer = {
  /**
   * Indicates if timer is enabled or not.
   */
  timer: TExerciseTimerValue | null;
};

export type TExerciseRawText = {
  /**
   * Exercise raw text that needs to be parsed.
   */
  exerciseText: string;
};

export type TExerciseSentenceText = {
  /**
   * Identifier for a sentence text piece.
   */
  id: TExerciseIdentifier;
  /**
   * Text content for a piece of a sentence.
   */
  content: string;
};

export type TExerciseSentenceAnswer = TExerciseSentenceText;

export type TExerciseSentenceAnswerWithCorrectness = TExerciseSentenceAnswer & {
  /**
   * Indicates if answer is correct or not.
   */
  isCorrect: boolean;
};

export type TExerciseSentenceBlank = {
  /**
   * Identifier for a sentence blank item.
   */
  id: TExerciseIdentifier;
  /**
   * Indicates if blank has an answer or not.
   */
  isEmpty: boolean;
  /**
   * Indicates if blank has correct answer as `currentAnswer`.
   */
  isCorrectAnswer: boolean;
  /**
   * Indicates if user failed to pick correct answer with `maxAttempts` limit.
   */
  isAllAttemptsFailed: boolean;
  /**
   * Limitation on maximum attempts number to associate an answer with current blank.
   */
  maxAttempts: number;
  /**
   * Current attempts number that user associated answer with a blank.
   */
  currentAttempt: number;
  /**
   * Text hint that will be available only for admin and teacher.
   */
  hint: string | null;
};

export type TExerciseSentenceChunk<
  TBlank extends TExerciseSentenceBlank = TExerciseSentenceBlank
> = {
  /**
   * Identifier for a sentence chunk.
   */
  id: TExerciseIdentifier;
  /**
   * Raw text string of a sentence chunk.
   */
  raw: string;
  /**
   * Indicates if chunk is a blank.
   */
  isBlank: boolean;
  /**
   * Blank data. If `isBlank` is false then will be `null`.
   */
  blank: TBlank | null;
  /**
   * Text data. If `isBlank` is true then will be `null`.
   */
  text: TExerciseSentenceText | null;
};

export type TExerciseSentence<
  TBlank extends TExerciseSentenceBlank = TExerciseSentenceBlank
> = {
  /**
   * Identifier for a sentence.
   */
  id: TExerciseIdentifier;
  /**
   * Raw text string of a sentence with answers.
   */
  raw: string;
  /**
   * Raw full text sentence without answers.
   */
  inputSentence: string;
  /**
   * Split sentence into text strings.
   */
  splitSentence: string[];
  /**
   * Array of sentence chunks data.
   */
  chunks: TExerciseSentenceChunk<TBlank>[];
};
