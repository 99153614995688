import { ForgetPasswordCard } from "./components/ForgetPasswordCard";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import "./ForgetPassword.styles.scss";

export const ForgetPassword: React.FC = () => {
  return (
    <>
      <LogoIcon className="nb-page-forget-password-logo" />
      <ForgetPasswordCard />
    </>
  );
};
