import { get, post } from "api/api";
import { STUDENT_PROGRESS_ENDPOINTS } from "api/endpoints/student-progress";
import { TEditExercise, TExercise } from "src/types/api/exercise";
import { TUrlParam } from "src/types/utils";

export const getChapterStudentProgress = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam
): Promise<TExercise[]> =>
  get<TExercise[]>(
    STUDENT_PROGRESS_ENDPOINTS.CHAPTER_STUDENT_PROGRESS(
      courseId,
      lessonId,
      chapterId,
      studentId
    )
  );

export const updateChapterStudentProgress = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam,
  exercise: TEditExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  post<TExercise<TPayload, TText>>(
    STUDENT_PROGRESS_ENDPOINTS.CHAPTER_STUDENT_PROGRESS(
      courseId,
      lessonId,
      chapterId,
      studentId
    ),
    exercise
  );

export const getHomeworkStudentProgress = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  homeworkId: TUrlParam,
  studentId: TUrlParam
): Promise<TExercise[]> =>
  get<TExercise[]>(
    STUDENT_PROGRESS_ENDPOINTS.HOMEWORK_STUDENT_PROGRESS(
      courseId,
      lessonId,
      homeworkId,
      studentId
    )
  );

export const updateHomeworkStudentProgress = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam,
  exercise: TEditExercise<TPayload, TText>
): Promise<TExercise<TPayload, TText>> =>
  post<TExercise<TPayload, TText>>(
    STUDENT_PROGRESS_ENDPOINTS.HOMEWORK_STUDENT_PROGRESS(
      courseId,
      lessonId,
      chapterId,
      studentId
    ),
    exercise
  );
