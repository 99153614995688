import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLogin } from "hooks/api/auth";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { Route } from "constants/routes";
import { LoginContent, ValidationContent } from "content";
import { EMAIL_REGEX } from "constants/validation";
import type { TLoginFormValues } from "./LoginForm.types";
import "./LoginForm.styles.scss";

export const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TLoginFormValues>({
    mode: "onBlur",
  });
  const navigate = useNavigate();

  const { mutate: loginUser } = useLogin();

  const loginSubmitHandler = (formData: TLoginFormValues) => {
    loginUser(formData, {
      onSuccess: () => {
        navigate(Route.App.Schedule.ROOT.path);
        reset();
      },
    });
  };

  return (
    <form
      className="nb-page-component-login-form"
      onSubmit={handleSubmit(loginSubmitHandler)}
    >
      <Input<TLoginFormValues>
        id="login-email"
        type="email"
        name="email"
        className="nb-page-component-login-email"
        label={LoginContent.Form.Label.Email}
        placeholder={LoginContent.Form.Field.Email}
        register={register}
        errors={errors}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        required
        autoFocus
        fullWidth
      />
      <Input<TLoginFormValues>
        id="login-password"
        type="password"
        name="password"
        label={LoginContent.Form.Label.Password}
        placeholder={LoginContent.Form.Field.Password}
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <div className="nb-page-component-login-form-footer">
        <Link
          className={"nb-page-component-login-request-password-button"}
          to={Route.Auth.RequestResetPassword.ROOT.path}
        >
          {LoginContent.Form.Button.ResetPassword}
        </Link>
      </div>
      <Button type="submit" fullWidth>
        {LoginContent.Form.Button.Submit}
      </Button>
    </form>
  );
};
