import { useMutation } from "react-query";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { changePassword } from "api/services/auth";
import { logError } from "utils/notifications";
import { TChangePasswordBody } from "types/api/auth";
import { TApiError } from "types/api/error";

export const useChangePassword = () => {
  return useMutation<void, TApiError, TChangePasswordBody>(
    EAuthQueryKeys.changePassword,
    changePassword,
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
