import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { detachCourseLessonFromStudent } from "api/services/courses";
import { logError } from "utils/notifications";
import { TDetachCourseLessonFromStudentBody } from "types/api/course";
import { TApiError } from "types/api/error";

export const useDetachCourseLessonFromStudent = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, TDetachCourseLessonFromStudentBody>(
    detachCourseLessonFromStudent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourseLessons);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
