import { useMutation, useQueryClient } from "react-query";
import { ECoursesQueryKeys } from "api/endpoints/courses";
import { detachCourseFromStudent } from "api/services/courses";
import { logError } from "utils/notifications";
import { TDetachCourseFromStudentBody } from "types/api/course";
import { TApiError } from "types/api/error";

export const useDetachCourseFromStudent = () => {
  const queryClient = useQueryClient();
  return useMutation<void, TApiError, TDetachCourseFromStudentBody>(
    detachCourseFromStudent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ECoursesQueryKeys.getAllCourses);
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
