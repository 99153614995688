import { CourseDetailsContent } from "content";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { IconButton } from "components/Interactive/IconButton";
import { Button } from "components/Interactive/Button";
import "./CourseHeroActions.styles.scss";
import { useRole } from "hooks/common";

type CourseHeroActionsProps = {
  /**
   * Callback function fires when edit course button click occurred.
   * @returns {void} nothing.
   */
  onEditClick?: () => void;
  /**
   * Callback function fires when delete course button click occurred.
   * @returns {void} nothing.
   */
  onDeleteClick?: () => void;
  /**
   * Callback function fires when add course button click occurred.
   * @returns {void} nothing.
   */
  onAddClick?: () => void;
  onAssignClick?: () => void;
};

export const CourseHeroActions: React.FC<CourseHeroActionsProps> = (props) => {
  const { onEditClick, onDeleteClick, onAddClick } = props;

  const { isUserAdmin } = useRole();

  return (
    <div className="nb-sub-materials-details-action">
      {isUserAdmin && (
        <>
          <IconButton
            className="nb-sub-materials-details-action-button"
            icon={<Edit />}
            onClick={onEditClick}
          />
          <IconButton
            className="nb-sub-materials-details-action-button"
            icon={<Delete />}
            onClick={onDeleteClick}
          />
          <Button
            className="nb-sub-materials-details-action-button"
            onClick={onAddClick}
          >
            {CourseDetailsContent.Hero.Action.Button.ADD_LESSON}
          </Button>
        </>
      )}
    </div>
  );
};
