import { EStudentProgressQueryKeys } from "api/endpoints/student-progress";
import { getHomeworkStudentProgress } from "api/services/student-progress";
import { useQuery } from "react-query";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useGetHomeworkStudentProgress = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam
) => {
  return useQuery<TExercise[], TApiError>(
    [
      EStudentProgressQueryKeys.getHomeworkStudentProgress,
      courseId,
      lessonId,
      chapterId,
      studentId,
    ],
    () => getHomeworkStudentProgress(courseId, lessonId, chapterId, studentId),
    {
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
