import {
  useUpdateChapterStudentProgress,
  useUpdateHomeworkStudentProgress,
} from "hooks/api/student-progress";
import { useRole } from "hooks/common";
import { useIsClassroom } from "hooks/common/useIsClassroom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EssayExerciseContent } from "content";
import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { Image } from "components/Common/Image";
import { Textarea } from "components/Interactive/Textarea";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Paragraph } from "components/Typography/Paragraph";
import { useParams } from "react-router-dom";
import {
  EEssayExerciseType,
  TEssayExercise,
  TEssayExercisePayload,
} from "types/app/exercises";
import { TEssayFormValues } from "./Essay.types";
import "./Essay.styles.scss";

type TEssayProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TEssayExercise;
  isHomeworkExercises: boolean;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const Essay: React.FC<TEssayProps> = (props) => {
  const { chapterId, exercise, prefix = null, isHomeworkExercises } = props;

  const [isLocked, setIsLocked] = useState<boolean>(false);
  const { isExerciseReadOnlyMode } = useLessonSettings();
  const { isClassroom } = useIsClassroom();
  const { isUserStudent } = useRole();
  const { courseId, lessonId, studentId } = useParams();

  const { mutate: updateChapter } = useUpdateChapterStudentProgress(
    courseId,
    lessonId,
    chapterId,
    studentId
  );

  const { mutate: updateHomework } = useUpdateHomeworkStudentProgress(
    courseId,
    lessonId,
    chapterId,
    studentId
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TEssayFormValues>({
    mode: "onBlur",
    defaultValues: { essay: "" },
  });

  useEffect(() => {
    const lastAnswer = exercise.payload.data.answers?.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )[0];

    if (lastAnswer) {
      setValue("essay", lastAnswer.answer);
      setIsLocked(true);
    }
  }, [exercise.payload.data.answers, setValue]);

  const saveEssayClickHandler = handleSubmit((data) => {
    if (isLocked) {
      setIsLocked(false);
      return;
    }

    if (!data.essay) return;

    const newAnswer = {
      date: new Date().toISOString(),
      answer: data.essay,
    };

    const updatedAnswers = exercise.payload.data.answers
      ? [...exercise.payload.data.answers, newAnswer]
      : [newAnswer];

    const updatedExercise = {
      ...exercise,
      payload: {
        ...exercise.payload,
        data: {
          ...exercise.payload.data,
          answers: updatedAnswers,
        },
      },
    };

    if (isHomeworkExercises) {
      updateHomework(updatedExercise);
    } else {
      updateChapter(updatedExercise);
    }

    setIsLocked(true);
  });

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TEssayExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      {exercise.payload.data.fileUrl && (
        <Image
          src={exercise.payload.data.fileUrl}
          alt="Essay related image"
          className="nb-exercise-essay-image"
          fullWidth
          rounded
        />
      )}
      <Paragraph variant={2} lineBreak>
        {exercise.payload.data.description}
      </Paragraph>
      {exercise.payload.data.essayType === EEssayExerciseType.ESSAY && (
        <Textarea<TEssayFormValues>
          name="essay"
          id="essay-exercise-essay"
          className="nb-exercise-essay-text"
          placeholder={EssayExerciseContent.Main.Input.Essay.PLACEHOLDER}
          register={register}
          errors={errors}
          disabled={
            isLocked || isExerciseReadOnlyMode || !isUserStudent || !isClassroom
          }
          buttonText={
            isLocked
              ? EssayExerciseContent.Main.Input.Essay.CHANGE
              : EssayExerciseContent.Main.Input.Essay.SAVE
          }
          onButtonClick={saveEssayClickHandler}
          withButton
          buttonProps={{ disabled: isExerciseReadOnlyMode }}
          required
          fullWidth
        />
      )}
    </Space>
  );
};
