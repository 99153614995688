import { Space } from "components/Common/Space";
import { Text } from "components/Typography/Text";
import { DraggableItem } from "../DraggableItem";
import { Droppable } from "../Droppable";
import {
  TExerciseIdentifier,
  TExerciseSentence,
  TMoveWordsIntoGapsExerciseBlank,
} from "types/app/exercises";
import "./DroppableItem.styles.scss";

type TDroppableItemProps = {
  /**
   * A sentence data to construct an exercise.
   */
  sentence: TExerciseSentence<TMoveWordsIntoGapsExerciseBlank>;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates whenever to show exercise results.
   * @default false
   */
  showResult?: boolean;
  /**
   * Currently dragged active item identifier.
   * @default null
   */
  dragActiveId?: TExerciseIdentifier | null;
};

export const DroppableItem: React.FC<TDroppableItemProps> = (props) => {
  const {
    dragging = false,
    showResult = false,
    sentence,
    dragActiveId = null,
  } = props;

  return (
    <Space
      direction="horizontal"
      align="center"
      justify="start"
      size={["xx-small", "xx-small"]}
      wrap
    >
      {sentence.chunks.map((chunk) =>
        chunk.blank !== null ? (
          <Droppable
            attemptsUsed={chunk.blank.currentAttempt}
            isSuccessful={chunk.blank.isCorrectAnswer}
            key={chunk.id}
            blank={chunk.blank}
            dragging={dragging}
            failed={chunk.blank.isAllAttemptsFailed}
          >
            {chunk.blank.currentAnswer !== null &&
              chunk.blank.currentAnswer.id !== dragActiveId && (
                <DraggableItem
                  answer={chunk.blank.currentAnswer}
                  correct={chunk.blank.isCorrectAnswer}
                  showResult={showResult}
                />
              )}
          </Droppable>
        ) : (
          chunk.text?.content.split(" ").map((word, index) => (
            <Text key={`${chunk.id}-${word}-${index}`} variant="body2">
              {word}
            </Text>
          ))
        )
      )}
    </Space>
  );
};
