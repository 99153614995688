import React from "react";
import cx from "classnames";
import "./AttemptIndicator.styles.scss";

type TAttemptIndicatorProps = {
  /**
   * Количество попыток, которые остались.
   */
  attempts: number;
  /**
   * Максимальное количество попыток.
   * @default 3
   */
  maxAttempts?: number;
  /**
   * Показывает, успешно ли последняя попытка.
   */
  isSuccessful?: boolean;
};

export const AttemptIndicator: React.FC<TAttemptIndicatorProps> = ({
  attempts,
  maxAttempts = 3,
  isSuccessful = false,
}) => {
  return (
    <div className="attempt-indicator">
      {Array.from({ length: maxAttempts }).map((_, index) => (
        <div
          key={index}
          className={cx("attempt-indicator__circle", {
            "attempt-indicator__circle--success":
              isSuccessful && index === attempts - 1,
            "attempt-indicator__circle--error":
              !isSuccessful && index === maxAttempts - attempts,
          })}
        ></div>
      ))}
    </div>
  );
};
