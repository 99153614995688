import { requestResetPassword } from "api/services/auth";
import { useMutation } from "react-query";
import { EAuthQueryKeys } from "api/endpoints/auth";
import { logError } from "utils/notifications";
import { TRequestResetPasswordBody } from "types/api/auth";
import { TApiError } from "types/api/error";

export const useRequestResetPassword = () => {
  return useMutation<void, TApiError, TRequestResetPasswordBody>(
    EAuthQueryKeys.requestResetPassword,
    requestResetPassword,
    {
      onError: (error) => {
        logError({ error });
      },
      retry: false,
    }
  );
};
