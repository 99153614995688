import { useRole } from "hooks/common";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Search } from "components/Interactive/Search";
import { Select } from "components/Interactive/Select";
import { Button } from "components/Interactive/Button";
import { logError } from "utils/notifications";
import { Route } from "constants/routes";
import { SelectTargetLabel, selectTargetOptions } from "./StudySearch.helpers";
import { StudyContent } from "content";
import { EStudySearchTarget } from "types/app/study";
import { TSelectOption } from "types/app/select";
import { TStudySearchForm } from "./StudySearch.types";
import "./StudySearch.styles.scss";

type StudySearchProps = {
  searchTarget?: EStudySearchTarget;
  searchPlaceholder?: string;
  searchCallback?: (searchValue: string) => Promise<TSelectOption<number>[]>;
  setSearchValue?: (value: string) => void;
  onAddButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export const StudySearch: React.FC<StudySearchProps> = (props) => {
  const {
    searchTarget = EStudySearchTarget.Teachers,
    searchPlaceholder,
    searchCallback,
    setSearchValue,
    onAddButtonClick,
  } = props;

  const { isUserTeacher } = useRole();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { control, watch } = useForm<TStudySearchForm>({
    defaultValues: {
      search: "",
      target: {
        label: SelectTargetLabel[searchTarget],
        value: searchTarget,
      },
    },
  });

  const data = watch();

  useEffect(() => {
    const {
      target: { value },
    } = data;

    switch (value) {
      case EStudySearchTarget.Teachers:
        if (pathname.includes(Route.App.Study.TEACHERS.path)) {
          break;
        }
        navigate(Route.App.Study.TEACHERS.path);
        break;

      case EStudySearchTarget.Students:
        if (pathname.includes(Route.App.Study.STUDENTS.path)) {
          break;
        }
        navigate(Route.App.Study.STUDENTS.path);
        break;

      case EStudySearchTarget.Groups:
        if (pathname.includes(Route.App.Study.GROUPS.path)) {
          break;
        }
        navigate(Route.App.Study.GROUPS.path);
        break;

      case EStudySearchTarget.Reports:
        if (pathname.includes(Route.App.Study.REPORTS.path)) {
          break;
        }
        navigate(Route.App.Study.REPORTS.path);
        break;

      case EStudySearchTarget.Requests:
        if (pathname.includes(Route.App.Study.REQUESTS.path)) {
          break;
        }
        navigate(Route.App.Study.REQUESTS.path);
        break;

      default:
        logError({ message: "Invalid study search target was provided" });
    }
  }, [data, navigate, pathname]);

  useEffect(() => {
    setSearchValue?.(data.search);
  }, [data.search, setSearchValue]);

  const filteredTargetOptions = isUserTeacher
    ? selectTargetOptions.filter((option) =>
        [
          EStudySearchTarget.Students,
          EStudySearchTarget.Groups,
          EStudySearchTarget.Reports,
        ].includes(option.value)
      )
    : selectTargetOptions;

  return (
    <div className="nb-sub-study-search-container">
      <Search<TStudySearchForm, TSelectOption<number>>
        name="search"
        control={control}
        id="study-search"
        placeholder={searchPlaceholder}
        searchCallback={searchCallback}
        fullWidth
        bordered
        filterSearch
        className="nb-sub-study-search"
      />
      <Select<TStudySearchForm, TSelectOption>
        name="target"
        control={control}
        options={filteredTargetOptions}
        id="study-search-target"
        className="nb-sub-study-search-select"
      />
      <Button
        disabled={isUserTeacher}
        onClick={onAddButtonClick}
        className="nb-sub-study-search-button"
      >
        {StudyContent.Button.ADD}
      </Button>
    </div>
  );
};
