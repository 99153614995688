import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { DndContainer } from "./DndContainer";
import {
  TMoveWordsIntoGapsExercise,
  TMoveWordsIntoGapsExercisePayload,
} from "types/app/exercises";
import "./MoveWordsIntoGaps.styles.scss";

type TMoveWordsIntoGapsProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TMoveWordsIntoGapsExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const MoveWordsIntoGaps: React.FC<TMoveWordsIntoGapsProps> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TMoveWordsIntoGapsExercisePayload>
        chapterId={chapterId}
        prefix={prefix}
        exercise={exercise}
      />
      <DndContainer exerciseData={exercise.payload.data} />
    </Space>
  );
};
