import { RequestResetPasswordCard } from "./components/RequestResetPasswordCard";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import "./RequestResetPassword.styles.scss";

export const RequestResetPassword: React.FC = () => {
  return (
    <>
      <LogoIcon className="nb-page-request-reset-password-logo" />
      <RequestResetPasswordCard />
    </>
  );
};
