import { LessonDetailsContent } from "content";
import { Text } from "components/Typography/Text";
import "./ChapterNameText.styles.scss";

type ChapterNameTextProps = {
  /**
   * Text label of the lesson chapter name.
   * @default 'Розділ'
   */
  text?: string;
  /**
   * Emoji string will be displayed right before the name.
   * @default '🖍'
   */
  emoji?: string;
};

export const ChapterNameText: React.FC<ChapterNameTextProps> = (props) => {
  const {
    text = LessonDetailsContent.Sidebar.Chapters.TEXT,
    emoji = LessonDetailsContent.Sidebar.Chapters.EMOJI,
  } = props;

  return (
    <Text className="nb-lesson-details-chapter-name">
      {emoji} <strong>{text}</strong>
    </Text>
  );
};
