export const Login = {
  Admin: {
    Title: "Хей 👋",
    Text: "Раді бачити тебе \nу not_botan_school!",
  },
  Form: {
    Field: {
      Email: "Email",
      Password: "Password",
    },
    Label: {
      Email: "Ваша електронна адреса",
      Password: "Ваш пароль",
    },
    Button: {
      ResetPassword: "Забули пароль?",
      Submit: "Увійти",
    },
  },
  ForgetPassword: {
    Title: "Хей 👋",
    Text: "Для відновлення пароля заповніть наступні поля",
    Field: {
      Password: "Новий пароль",
      RepeatedPassword: "Новий пароль ще раз",
    },
    Label: {
      Password: "Ваш новий пароль",
      RepeatedPassword: "Ваш новий пароль ще раз",
    },
    Button: {
      Submit: "Відновити пароль",
    },
    Notification: {
      SUCCESS: "Пароль успішно відновлено!",
    },
  },
  RequestResetPassword: {
    Admin: {
      Title: "Упс 😛",
      Text: "Введіть вашу пошту для відновлення паролю",
    },
    Field: {
      Email: "Ваш email",
    },
    Label: {
      Email: "Email",
    },
    Button: {
      Submit: "Відновити",
    },
    Notification: {
      SUCCESS: "Лист-відновлення на вашу пошту надісланий",
    },
  },
};
