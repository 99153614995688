import { LoginCard } from "./components/LoginCard";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import "./Login.styles.scss";

export const Login: React.FC = () => {
  return (
    <>
      <LogoIcon className="nb-page-login-logo" />
      <LoginCard />
    </>
  );
};
