import { Fragment } from "react";
import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Divider } from "components/Common/Divider";
import { DndContainer } from "./DndContainer";
import {
  TMakeWordFromLettersExercise,
  TMakeWordFromLettersExercisePayload,
} from "types/app/exercises";
import "./MakeWordFromLetters.styles.scss";

type TMakeWordFromLettersProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TMakeWordFromLettersExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const MakeWordFromLetters: React.FC<TMakeWordFromLettersProps> = (
  props
) => {
  const { chapterId, exercise, prefix = null } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TMakeWordFromLettersExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      <Space direction="vertical" size="medium" fullWidth>
        {exercise.payload.data.words.map((word, index) => (
          <Fragment key={word.id}>
            <DndContainer word={word} />
            {index + 1 !== exercise.payload.data.words.length && (
              <Divider variant="dashed" />
            )}
          </Fragment>
        ))}
      </Space>
    </Space>
  );
};
