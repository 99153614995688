import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { ExerciseContainer } from "./ExerciseContainer";
import {
  TCompareImageWithDescriptionExercise,
  TCompareImageWithDescriptionExercisePayload,
} from "types/app/exercises";
import "./CompareImageWithDescription.styles.scss";

type TCompareImageWithDescriptionProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TCompareImageWithDescriptionExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const CompareImageWithDescription: React.FC<
  TCompareImageWithDescriptionProps
> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TCompareImageWithDescriptionExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      <ExerciseContainer exercise={exercise} />
    </Space>
  );
};
