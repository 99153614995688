import { useRole } from "hooks/common";
import { useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { StudentClassroomContent as Content } from "content";
import { Avatar } from "components/Common/Avatar";
import { Space } from "components/Common/Space";
import { Button } from "components/Interactive/Button";
import { Tooltip } from "components/Interactive/Tooltip";
import { ReactComponent as FilePin } from "assets/icons/file-plus.svg";
import { ReactComponent as FileUnPin } from "assets/icons/file-minus.svg";
import { getRowSequenceNumber } from "utils/table";
import { TStudentCourseLesson } from "types/api/course";

type TUseAddLessonToStudentColumns = (
  courseId?: string,
  options?: {
    onPinClick?: (courseLessonId: number) => void;
    onUnPinClick?: (courseLessonId: number) => void;
    onViewClick?: (courseLessonId: number) => void;
  }
) => ColumnDef<TStudentCourseLesson, string>[];

export const useAddLessonToStudentColumns: TUseAddLessonToStudentColumns = (
  courseId,
  options
) => {
  const { onPinClick, onUnPinClick, onViewClick } = options || {};

  const columnHelper = createColumnHelper<TStudentCourseLesson>();

  const { isUserStudent } = useRole();
  const columns: ColumnDef<TStudentCourseLesson, string>[] = useMemo(
    () => [
      columnHelper.accessor(() => "index", {
        id: "index",
        cell: (info) =>
          getRowSequenceNumber(
            info.row.index,
            info.table.getState().pagination
          ),
        size: 0.1,
      }),
      columnHelper.accessor(() => "avatar", {
        id: "avatar",
        cell: (info) => (
          <Avatar bordered size="small" image={info.row.original.imageUrl} />
        ),
        size: 0.15,
      }),
      columnHelper.accessor(() => "name", {
        id: "name",
        cell: (info) => (
          <Tooltip
            mouseEnterDelay={1000}
            text={info.row.original.name}
            spaceSize="small"
            variant="compact"
          >
            {info.row.original.name}
          </Tooltip>
        ),
        size: 0.6,
      }),
      columnHelper.accessor(() => "action", {
        id: "action",
        cell: ({ row }) => (
          <Space direction="horizontal" size="x-small">
            <Button
              variant="secondary"
              onClick={() => onViewClick?.(row.original.id)}
            >
              {Content.Table.Action.VIEW}
            </Button>
            {!isUserStudent && (
              <Button
                variant="primary"
                iconLeft={
                  row.original.isPinned ? (
                    <FileUnPin width={18} height={20} />
                  ) : (
                    <FilePin width={18} height={20} />
                  )
                }
                onClick={() =>
                  row.original.isPinned
                    ? onUnPinClick?.(row.original.id)
                    : onPinClick?.(row.original.id)
                }
                className="nb-sub-student-classroom-table-action"
              >
                {row.original.isPinned
                  ? Content.Table.Action.UNPIN
                  : Content.Table.Action.PIN}
              </Button>
            )}
          </Space>
        ),
        size: 280,
      }),
    ],
    // Adding only courseId as dependency to prevent avatar file refetch on every rerender
    [courseId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return columns;
};
