import { AttemptIndicator } from "components/Common/AttemptIndicator";
import { useRole } from "hooks/common";
import { forwardRef, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Space } from "components/Common/Space";
import { Tooltip } from "components/Interactive/Tooltip";
import "./ClueInput.styles.scss";

type TClueInputProps = {
  /**
   * Name attribute of the `input` element.
   */
  name: string;
  /**
   * The id of the `input` element.
   * Provide if label is used.
   */
  id: string;
  /**
   * Input label text for the `input` element.
   */
  label?: string;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: string | null;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: string;
  /**
   * Default value of the `input` element.
   * @default ''
   */
  defaultValue?: string;
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   * @default 'text'
   */
  type?: React.HTMLInputTypeAttribute;
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it [following the specification](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill).
   */
  autoComplete?: string;
  /**
   * If `true`, the `input` element is focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the `ClueInput` element is required.
   * @default false
   */
  required?: boolean;
  /**
   * If `true`, the `input` will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the `ClueInput` will indicate an error.
   * @default false
   */
  error?: boolean;
  /**
   * If `true`, the `ClueInput` will indicate a success.
   * @default false
   */
  success?: boolean;
  /**
   * Hint text to be displayed in the tooltip.
   * @default null
   */
  hint?: string | null;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
  /**
   * Override or extend the styles applied to the component container.
   */
  containerClassName?: string;
  /**
   * Override or extend the styles applied to the component.
   */
  style?: React.CSSProperties;
  /**
   * If `true`, the `input` element will take up the width of its value.
   * @default false
   */
  fitValue?: boolean;
  /**
   * A function to handle a change of the `ClueInput`.
   * @param value - The new value of the `input` element.
   * @param id - The id of the `input`.
   */
  onChange?: (
    value: string,
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  /**
   * A function to handle a blur of the `ClueInput`.
   * @param value - The new value of the `input` element.
   * @param id - The id of the `input`.
   */
  onBlur?: (value: string, id: string) => void;
  attemptsUsed?: number;
  isSuccessful?: boolean;
};

export const ClueInput = forwardRef<HTMLInputElement, TClueInputProps>(
  (props, ref) => {
    const {
      name,
      id,
      label,
      placeholder = "",
      defaultValue = "",
      type = "text",
      autoComplete,
      autoFocus = false,
      disabled = false,
      required = false,
      fullWidth = false,
      error = false,
      success = false,
      fitValue = false,
      hint = null,
      value = null,
      className,
      containerClassName,
      style,
      onChange,
      onBlur,
      attemptsUsed = 0,
      isSuccessful = false,
    } = props;

    const shadowRef = useRef<HTMLSpanElement>(null);
    const { isUserStudent, isUserTeacher } = useRole();

    const [inputValue, setInputValue] = useState<string>(defaultValue);
    const [width, setWidth] = useState<number>(0);
    const effectiveValue: string = value === null ? inputValue : value;

    useEffect(() => {
      if (fitValue && shadowRef.current !== null) {
        setWidth(shadowRef.current.offsetWidth);
      }
    }, [value, fitValue]);

    const clueInputChangeHandler = (
      evt: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (effectiveValue === evt.target.value || disabled) {
        return;
      }
      setInputValue(evt.target.value);
      onChange?.(evt.target.value, id, evt);
    };

    const clueInputBlurHandler = (evt: React.FocusEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }
      onBlur?.(evt.target.value, id);
    };

    const clueInputKeyDownHandler = (
      evt: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        if (!disabled) {
          onBlur?.(effectiveValue, id);
        }
      }
    };

    return (
      <div
        className={cx([
          "nb-interactive-clue-input-wrapper",
          {
            "nb-interactive-clue-input-wrapper--full": fullWidth,
          },
          containerClassName,
        ])}
        aria-live="polite"
      >
        {label && (
          <label className="nb-interactive-clue-input-label" htmlFor={id}>
            {label}
          </label>
        )}
        <Space
          direction="horizontal"
          justify="start"
          align="center"
          size="xx-small"
          fullWidth={fullWidth}
        >
          <Tooltip
            text={hint}
            disabled={isUserStudent}
            trigger="hover"
            position="top"
            spaceSize="small"
            arrow="visible"
            containerClassName="nb-interactive-clue-input-tooltip-container"
            className="nb-interactive-clue-input-tooltip"
          >
            <input
              ref={ref}
              type={type}
              name={name}
              id={id}
              value={effectiveValue}
              placeholder={placeholder}
              required={required}
              disabled={disabled || isUserTeacher}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              style={{ ...(fitValue && { width }), ...style }}
              onChange={clueInputChangeHandler}
              onBlur={clueInputBlurHandler}
              onKeyDown={clueInputKeyDownHandler}
              className={cx([
                "nb-interactive-clue-input",
                {
                  "nb-interactive-clue-input--full": fullWidth,
                  "nb-interactive-clue-input--error": error,
                  "nb-interactive-clue-input--success": success,
                },
                className,
              ])}
            />
          </Tooltip>
          {fitValue && (
            <span
              aria-hidden
              ref={shadowRef}
              style={style}
              className="nb-interactive-clue-shadow"
            >
              {value}
            </span>
          )}

          <AttemptIndicator
            attempts={attemptsUsed}
            maxAttempts={3}
            isSuccessful={isSuccessful}
          />
        </Space>
      </div>
    );
  }
);

ClueInput.displayName = "ClueInput";
