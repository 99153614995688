import { useChangePassword } from "hooks/api/auth/useChangePassword";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { UserSettingsContent } from "content";
import { Button } from "components/Interactive/Button";
import { Input } from "components/Interactive/Input";
import { logSuccess } from "utils/notifications";
import { TChangePasswordFormValues } from "./ChangePasswordForm.types";
import "./ChangePasswordForm.styles.scss";

type TChangePasswordFormProps = {
  /**
   * Callback function fires on add course submit event.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const ChangePasswordForm: React.FC<TChangePasswordFormProps> = (
  props
) => {
  const { className, onSubmit } = props;

  const { mutate: changePassword } = useChangePassword();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<TChangePasswordFormValues>({
    mode: "onBlur",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const handleAddCourseSubmit = (formValues: TChangePasswordFormValues) => {
    const body = {
      currentPassword: formValues.oldPassword,
      password: formValues.newPassword,
      repeatedPassword: formValues.confirmNewPassword,
    };
    changePassword(body, {
      onSuccess: () => {
        logSuccess(UserSettingsContent.ChangePassword.Notification.SUCCESS);
        onSubmit?.();
        reset({
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      },
    });
  };

  return (
    <form
      className={cx(["nb-page-user-settings-change-password-form", className])}
      onSubmit={handleSubmit(handleAddCourseSubmit)}
    >
      <Input<TChangePasswordFormValues>
        id="change-password-old"
        type="password"
        name="oldPassword"
        label={UserSettingsContent.ChangePassword.Input.OldPassword.LABEL}
        placeholder={
          UserSettingsContent.ChangePassword.Input.OldPassword.PLACEHOLDER
        }
        register={register}
        errors={errors}
        autoFocus
        required
        fullWidth
      />
      <Input<TChangePasswordFormValues>
        id="change-password-new"
        type="password"
        name="newPassword"
        label={UserSettingsContent.ChangePassword.Input.NewPassword.LABEL}
        placeholder={
          UserSettingsContent.ChangePassword.Input.NewPassword.PLACEHOLDER
        }
        register={register}
        errors={errors}
        required
        fullWidth
      />
      <Input<TChangePasswordFormValues>
        id="change-password-confirm"
        type="password"
        name="confirmNewPassword"
        label={
          UserSettingsContent.ChangePassword.Input.ConfirmNewPassword.LABEL
        }
        placeholder={
          UserSettingsContent.ChangePassword.Input.ConfirmNewPassword
            .PLACEHOLDER
        }
        register={register}
        errors={errors}
        rules={{
          validate: (value) =>
            value === getValues("newPassword") ||
            UserSettingsContent.ChangePassword.Error.NOT_MATCH,
        }}
        required
        fullWidth
      />
      <Button
        className="nb-page-user-settings-change-password-submit"
        variant="primary"
        type="submit"
        fullWidth
      >
        {UserSettingsContent.ChangePassword.Button.SUBMIT}
      </Button>
    </form>
  );
};
