import { Link } from "react-router-dom";
import { Divider } from "components/Common/Divider";
import { SiteNavigation } from "components/Navigation/SiteNavigation";
import { ReactComponent as Mail } from "assets/icons/mail.svg";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { NavigationContent } from "content";
import "./Sidebar.styles.scss";

export const Sidebar: React.FC = () => {
  return (
    <aside className="nb-layout-sidebar">
      <Link
        className="nb-layout-sidebar-logo"
        to={NavigationContent.Main.Schedule}
      >
        <LogoIcon className="nb-layout-sidebar-logo-icon" />
      </Link>
      <Divider />
      <div className="nb-layout-sidebar-menu">
        <SiteNavigation />
      </div>
      <div className="nb-layout-sidebar-image">
        <img
          src="/assets/images/sidebar-alina.png"
          width={166}
          height={192}
          alt="Аліна - засновник школи"
        />
      </div>
      <Divider />
      <div className="nb-layout-sidebar-support">
        <Link
          to={NavigationContent.Main.Support.Link}
          target="_blank"
          rel="noopener noreferrer"
          className="nb-layout-sidebar-support-link"
        >
          <span className="nb-layout-sidebar-support-icon">
            <Mail />
          </span>
          <span className="nb-layout-sidebar-support-text">
            {NavigationContent.Main.Support.Text}
          </span>
        </Link>
      </div>
    </aside>
  );
};
