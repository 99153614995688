import { Route } from "constants/routes";
import { useLocation } from "react-router-dom";
import { NavigationItem } from "components/Navigation/NavigationItem";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { SITE_NAVIGATION } from "constants/navigation";
import { EUserRole } from "types/api/role";
import "./SiteNavigation.styles.scss";

export const SiteNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const { activeUser } = useActiveUser();

  const userRole = activeUser?.role.name || EUserRole.STUDENT;

  return (
    <nav className="nb-site-navigation">
      <ul className="nb-site-navigation-list">
        {SITE_NAVIGATION.filter((item) => item.access.includes(userRole)).map(
          (item) => {
            const link =
              item.path === Route.App.Study.STUDENT_CLASSROOM.path
                ? Route.App.Study.STUDENT_CLASSROOM.construct(
                    activeUser?.studentId
                  )
                : item.path;

            return (
              <li key={item.path} className="nb-site-navigation-list-item">
                <NavigationItem
                  link={link}
                  label={item.label}
                  icon={item.icon}
                  active={pathname.includes(item.path)}
                  disabled={item.disabled}
                />
              </li>
            );
          }
        )}
      </ul>
    </nav>
  );
};
