export enum EAuthQueryKeys {
  login = "login",
  logout = "logout",
  getCurrentUser = "getCurrentUser",
  changePassword = "changePassword",
  resetPassword = "resetPassword",
  requestResetPassword = "requestResetPassword",
}

export const AUTH_ENDPOINTS = {
  LOGIN: "/login",
  LOGOUT: "/login/logout",
  CURRENT_USER: "/login/current",
  CHANGE_PASSWORD: "/login/change-password",
  RESET_PASSWORD: "/login/confirm-forget-password",
  FORGET_PASSWORD: "/login/forget-password",
};
