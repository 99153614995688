export const Materials = {
  Name: "Матеріали",
  Emoji: "💰",
  EmptyForStudent: "Матеріали не знайдено",
  Search: {
    PLACEHOLDER: "Пошук матеріалів",
    NO_OPTIONS: "Матеріали не знайдено",
  },
  Tabs: {
    Catalog: {
      TITLE: "Каталог",
    },
    Personal: {
      TITLE: "Особисті",
    },
  },
  Button: {
    Add: {
      TITLE: "Відкрити форму для створення нового курсу",
    },
  },
  Add: {
    TITLE: "Створити новий курс",
    EMOJI: "📒",
    SUBMIT: "Створити курс",
    Notification: {
      SUCCESS: "Курс успішно створено",
      ERROR: "Не вдалось створити курс",
    },
    Input: {
      Name: {
        LABEL: "Назва",
        PLACEHOLDER: "Вступний курс №1",
      },
      Language: {
        LABEL: "Мова",
        PLACEHOLDER: "Оберіть мову",
      },
      Level: {
        LABEL: "Рівень",
        PLACEHOLDER: "Оберіть рівень знань",
      },
      Description: {
        LABEL: "Опис курсу",
        PLACEHOLDER: "Докладніший опис курсу...",
      },
    },
  },
};
