import { useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Button } from "components/Interactive/Button";
import { TButtonExercise, TButtonExercisePayload } from "types/app/exercises";
import "./Button.styles.scss";

type TButtonExerciseProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TButtonExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const ButtonExercise: React.FC<TButtonExerciseProps> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  const { isExercisePreviewMode } = useLessonSettings();

  const buttonClickHandler = () => {
    if (window && exercise.payload.data.url) {
      window.open(exercise.payload.data.url, "_blank");
    }
  };

  return (
    <Space
      direction="vertical"
      size="medium"
      justify="start"
      align="start"
      fullWidth
    >
      <ExerciseHeader<TButtonExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />

      <Button
        variant="primary"
        onClick={buttonClickHandler}
        className="nb-exercise-button-self"
        disabled={isExercisePreviewMode}
      >
        {exercise.payload.data.label}
      </Button>
    </Space>
  );
};
