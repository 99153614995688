import { EStudentProgressQueryKeys } from "api/endpoints/student-progress";
import { updateHomeworkStudentProgress } from "api/services/student-progress";
import { useMutation, useQueryClient } from "react-query";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TEditExercise, TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useUpdateHomeworkStudentProgress = <
  TPayload = Record<string, unknown>,
  TText extends string = string
>(
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam
) => {
  const queryClient = useQueryClient();
  return useMutation<
    TExercise<TPayload, TText>,
    TApiError,
    TEditExercise<TPayload, TText>
  >(
    (chapterExercise: TEditExercise<TPayload, TText>) =>
      updateHomeworkStudentProgress<TPayload, TText>(
        courseId,
        lessonId,
        chapterId,
        studentId,
        chapterExercise
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          EStudentProgressQueryKeys.getChapterStudentProgress
        );
      },
      onError: (error) => logError({ error }),
      retry: false,
    }
  );
};
