import { useParams } from "react-router-dom";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ExerciseConstructorContent as Content } from "content";
import { useModal, useRole } from "hooks/common";
import {
  useRemoveExercise,
  useRemoveHomeworkExercise,
} from "hooks/api/exercises";
import { useExerciseConstructor, useLessonSettings } from "hooks/redux";
import { Space } from "components/Common/Space";
import { ActionModal } from "components/Common/ActionModal";
import { IconButton } from "components/Interactive/IconButton";
import { ExerciseCondition } from "components/Typography/ExerciseCondition";
import { logError, logSuccess } from "utils/notifications";
import { TExercise } from "types/api/exercise";
import "./ExerciseHeader.styles.scss";

type TExerciseHeaderProps<TExercisePayload = Record<string, unknown>> = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TExercise<TExercisePayload>;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const ExerciseHeader = <
  TExercisePayload extends Record<string, unknown> = Record<string, unknown>
>(
  props: TExerciseHeaderProps<TExercisePayload>
): JSX.Element => {
  const { chapterId, exercise, prefix = null } = props;

  const { courseId, lessonId } = useParams();
  const { isUserAdmin } = useRole();

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const { setEditExercise, constructorMode, homeworkId } =
    useExerciseConstructor<TExercisePayload>();

  const { viewMode, isExerciseLiveMode } = useLessonSettings();

  const { mutate: removeExercise } = useRemoveExercise(
    courseId,
    lessonId,
    chapterId
  );
  const { mutate: removeHomeworkExercise } = useRemoveHomeworkExercise(
    courseId,
    lessonId,
    homeworkId
  );

  const exerciseEditButtonClickHandler = () => {
    setEditExercise(chapterId, exercise);
  };

  const successCallback = () => {
    logSuccess(Content.Notification.Delete.SUCCESS);
    closeDeleteModal();
  };

  const exerciseDeleteButtonClickHandler = () => {
    if (!courseId || !lessonId || !(chapterId || homeworkId)) {
      logError({
        message: Content.Notification.Delete.ERROR,
      });
      return;
    }

    switch (constructorMode) {
      case "lesson":
        return removeExercise(exercise.id, {
          onSuccess: successCallback,
        });

      case "homework":
        return removeHomeworkExercise(exercise.id, {
          onSuccess: successCallback,
        });

      default:
        throw new Error("Unknown constructor mode");
    }
  };

  return (
    <Space
      direction="horizontal"
      size="medium"
      justify="between"
      align="center"
      fullWidth
    >
      <ExerciseCondition prefix={prefix} text={exercise.payload.text ?? ""} />
      {viewMode === "constructor" && !isExerciseLiveMode && isUserAdmin && (
        <>
          <Space
            direction="horizontal"
            size="x-small"
            justify="start"
            align="center"
          >
            <IconButton
              icon={<Edit />}
              variant="tertiary"
              size="small"
              onClick={exerciseEditButtonClickHandler}
            />
            <IconButton
              icon={<Delete />}
              variant="tertiary"
              size="small"
              onClick={openDeleteModal}
            />
          </Space>
          <ActionModal
            text={Content.Delete.TITLE}
            emoji={Content.Delete.EMOJI}
            open={isDeleteModalOpen}
            onClose={closeDeleteModal}
            onSubmit={exerciseDeleteButtonClickHandler}
          />
        </>
      )}
    </Space>
  );
};
