import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { Space } from "components/Common/Space";
import { Tooltip } from "components/Interactive/Tooltip";
import { useRole } from "hooks/common";
import {
  TSortWordsExerciseParsedColumn,
  TSortWordsExerciseParsedWord,
} from "types/app/exercises";
import "./Droppable.styles.scss";

type TDroppableProps = {
  /**
   * Word data to populate droppable component.
   */
  word: TSortWordsExerciseParsedWord;
  /**
   * A column data to construct an exercise.
   */
  column: TSortWordsExerciseParsedColumn;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates if a user is failed all attempts to pick the correct word on this droppable column.
   * @default false
   */
  failed?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
};

export const Droppable: React.FC<TDroppableProps> = (props) => {
  const { children, word, column, dragging = false } = props;

  const { isOver, setNodeRef } = useDroppable({
    id: word.id,
    data: { word, column },
  });
  const { isUserStudent } = useRole();

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="center"
      size="xx-small"
    >
      <Tooltip
        text={word.hint}
        disabled={isUserStudent}
        trigger="hover"
        position="top-start"
        spaceSize="small"
        arrow="visible"
        containerClassName="nb-exercise-sort-words-droppable-tooltip-container"
        className="nb-exercise-sort-words-droppable-tooltip"
      >
        <div
          ref={setNodeRef}
          className={cx("nb-exercise-sort-words-droppable", {
            "nb-exercise-sort-words-droppable--over": isOver,
            "nb-exercise-sort-words-droppable--dragging": dragging,
            "nb-exercise-sort-words-droppable--dropped": children,
          })}
          aria-label="Поле в яке можна перенести відповідь"
        >
          {children}
        </div>
      </Tooltip>
    </Space>
  );
};
