import { useGetChapterStudentProgress } from "hooks/api/student-progress";
import { useRole } from "hooks/common";
import { useIsClassroom } from "hooks/common/useIsClassroom/useIsClassroom";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { useExerciseConstructor, useLessonSettings } from "hooks/redux";
import { useScrollAndHighlight } from "hooks/common/useScrollAndHighlight";
import { useExercises } from "hooks/api/exercises";
import { LessonDetailsContent } from "content";
import { Button } from "components/Interactive/Button";
import { Divider } from "components/Common/Divider";
import { ChapterNameText, ExercisesPreview } from "components/Lesson";
import type { TCourseLessonChapter } from "types/api/course";
import "./LessonContentChapter.styles.scss";

type LessonContentChapterProps = {
  /**
   * Lesson chapter data to display.
   */
  chapter: TCourseLessonChapter;
  /**
   * The chapter sequence order.
   * @default 1
   */
  order?: number;
  /**
   * Indicates active state of chapter content item.
   * @default false
   */
  active?: boolean;
};

export const LessonContentChapter: React.FC<LessonContentChapterProps> = (
  props
) => {
  const { chapter, order = 1, active = false } = props;

  const { courseId, lessonId, studentId } = useParams();
  const { isUserAdmin } = useRole();

  const { isClassroom } = useIsClassroom();

  const ref = useRef<HTMLDivElement | null>(null);
  const { highlight } = useScrollAndHighlight(ref.current, {
    enable: active,
    highlightTime: 2000,
    scrollOffset: 20,
    delay: 50, // We need to delay for a bit before ref hook assign div element
  });

  const { openExerciseList, setChapterId } = useExerciseConstructor();
  const { viewMode, isExerciseLiveMode } = useLessonSettings();

  const { data: exercisesData, isLoading: exercisesLoading } = useExercises(
    !isClassroom ? courseId : null,
    !isClassroom ? lessonId : null,
    !isClassroom ? chapter.id : null
  );

  const { data: progressData, isLoading: progressLoading } =
    useGetChapterStudentProgress(
      isClassroom ? courseId : null,
      isClassroom ? lessonId : null,
      isClassroom ? chapter.id : null,
      isClassroom ? studentId : null
    );

  const chapterExercises = isClassroom ? progressData : exercisesData;
  const isLoading = isClassroom ? progressLoading : exercisesLoading;

  const openExerciseConstructorClickHandler = () => {
    setChapterId(chapter.id);
    openExerciseList();
  };

  return (
    <div ref={ref} className="nb-lesson-details-content-chapter-wrapper">
      <div className="nb-lesson-details-content-chapter">
        <div
          className={cx([
            "nb-lesson-details-content-chapter-title",
            {
              ["nb-lesson-details-content-chapter-title--highlight"]: highlight,
            },
          ])}
        >
          <ChapterNameText text={chapter.name} />
        </div>
        <ExercisesPreview
          exercises={chapterExercises}
          chapterOrder={order}
          chapterId={chapter.id}
          loading={isLoading}
          isHomeworkExercises={false}
        />
        {viewMode === "constructor" && !isExerciseLiveMode && isUserAdmin && (
          <Button
            variant="secondary"
            fullWidth
            onClick={openExerciseConstructorClickHandler}
          >
            {LessonDetailsContent.Content.Lesson.Button.ADD}
          </Button>
        )}
      </div>
      <Divider
        margin="yAxis"
        marginSize="large"
        className="nb-lesson-details-content-chapter-divider"
      />
    </div>
  );
};
