import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StudentClassroomContent as Content } from "content";
import { useBreadcrumbs } from "hooks/redux";
import { useModal, useRole } from "hooks/common";
import { useStudentById } from "hooks/api/students";
import { useCourses } from "hooks/api/courses";
import { Space } from "components/Common/Space";
import { Loader } from "components/Common/Loader";
import { MaterialsPagination } from "components/Courses/MaterialsPagination";
import { MaterialsCoverItem } from "components/Courses/MaterialsCoverItem";
import { ClassroomEmpty } from "./components/ClassroomEmpty";
import { AddStudentMaterialModal } from "./components/AddStudentMaterialModal";
import { Route } from "constants/routes";
import { TCourse } from "types/api/course";
import "./StudentClassroom.styles.scss";
import { Empty } from "components/Common/Empty";

export const StudentClassroom: React.FC = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { setPageName, resetPageName } = useBreadcrumbs();
  const {
    openModal: openAddMaterialModal,
    closeModal: closeAddMaterialModal,
    isModalOpen: isAddMaterialModalOpen,
  } = useModal(false);

  const { isUserStudent } = useRole();

  const { data: student, isLoading: isStudentLoading } =
    useStudentById(studentId);
  const { data: coursesData, isFetching: isCourseLoading } = useCourses({
    pageNumber,
    studentId,
  });

  const courses: TCourse[] = useMemo(
    () => coursesData?.content || [],
    [coursesData?.content]
  );
  const totalPages: number = useMemo(
    () => coursesData?.totalPages || 0,
    [coursesData?.totalPages]
  );
  const isEmpty: boolean = useMemo(() => courses.length === 0, [courses]);

  useEffect(() => {
    if (student?.person) {
      const fullName = `${Content.Name} ${student.person.firstName} ${student.person.lastName}`;
      setPageName(fullName);
    }
    return () => resetPageName();
  }, [student?.person, setPageName, resetPageName]);

  const pageChangeHandler = (newPage: number) => {
    setPageNumber(newPage);
  };

  const courseClickHandler = (courseId: number) => {
    if (!studentId) return;
    navigate(
      Route.App.Study.STUDENT_CLASSROOM_COURSE.construct(
        studentId,
        courseId.toString()
      )
    );
  };

  return (
    <>
      {isCourseLoading || isStudentLoading ? (
        <Loader />
      ) : isEmpty && !isCourseLoading && !isUserStudent ? (
        <ClassroomEmpty onAddClick={openAddMaterialModal} />
      ) : isEmpty && !isCourseLoading && isUserStudent ? (
        <Empty text={Content.EmptyForStudent} />
      ) : (
        <section className="nb-sub-students-classroom-container">
          <Space
            direction="vertical"
            justify="start"
            align="start"
            size="medium"
            fullWidth
          >
            <ul className="nb-sub-students-classroom-list">
              {/*{!isUserStudent && (*/}
              {/*  <li className="nb-sub-students-classroom-list-item">*/}
              {/*    <CoverButton onClick={openAddMaterialModal}>*/}
              {/*      {Content.Add.TITLE}*/}
              {/*    </CoverButton>*/}
              {/*  </li>*/}
              {/*)}*/}
              {courses.map((course) => (
                <MaterialsCoverItem
                  key={course.id}
                  course={course}
                  onClick={courseClickHandler}
                />
              ))}
            </ul>
            <MaterialsPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={pageChangeHandler}
            />
          </Space>
        </section>
      )}
      <AddStudentMaterialModal
        open={isAddMaterialModalOpen}
        existingCourses={courses}
        onClose={closeAddMaterialModal}
      />
    </>
  );
};
