import { TUrlParam } from "src/types/utils";

export enum EStudentProgressQueryKeys {
  getChapterStudentProgress = "getChapterStudentProgress",
  updateChapterStudentProgress = "updateChapterStudentProgress",
  getHomeworkStudentProgress = "getHomeworkStudentProgress",
  updateHomeworkStudentProgress = "updateHomeworkStudentProgress",
}

export const STUDENT_PROGRESS_ENDPOINTS = {
  CHAPTER_STUDENT_PROGRESS: (
    courseId: TUrlParam,
    lessonId: TUrlParam,
    chapterId: TUrlParam,
    studentId: TUrlParam
  ): string =>
    `/student-progress/${studentId}/courses/${courseId}/lessons/${lessonId}/chapters/${chapterId}/exercises`,
  HOMEWORK_STUDENT_PROGRESS: (
    courseId: TUrlParam,
    lessonId: TUrlParam,
    homeworkId: TUrlParam,
    studentId: TUrlParam
  ): string =>
    `/student-progress/${studentId}/courses/${courseId}/lessons/${lessonId}/homework/${homeworkId}/exercises`,
};
