export const Navigation = {
  Main: {
    Schedule: "Розклад",
    Progress: "Мій прогрес",
    Study: "Навчання",
    Materials: "Матеріали",
    Finance: "Фінанси",
    Support: {
      Text: "Підтримка",
      Link: "//t.me/not_botan_admin",
    },
  },
  Profile: {
    Settings: "Налаштування профілю",
    LandingDashboard: "Налаштування лендінгу",
    Logout: "Вийти",
  },
};
