import { sha256 } from "js-sha256";
import { get, post, put } from "api";
import { AUTH_ENDPOINTS } from "api/endpoints/auth";
import {
  TChangePasswordBody,
  TLogin,
  TLoginCredentials,
  TRequestResetPasswordBody,
  TResetPasswordBody,
} from "types/api/auth";

export const fetchCurrentUser = () => get<TLogin>(AUTH_ENDPOINTS.CURRENT_USER);

export const loginUser = async (
  formData: TLoginCredentials
): Promise<TLogin> => {
  const requestOject = {
    email: formData.email,
    password: sha256(formData.password),
  };
  return post<TLogin>(AUTH_ENDPOINTS.LOGIN, requestOject);
};

export const logoutUser = async (): Promise<void> => {
  return post(AUTH_ENDPOINTS.LOGOUT);
};

export const changePassword = async (
  formData: TChangePasswordBody
): Promise<void> => {
  const requestOject = {
    currentPassword: formData.currentPassword,
    password: formData.password,
    repeatedPassword: formData.repeatedPassword,
  };
  return put(AUTH_ENDPOINTS.CHANGE_PASSWORD, requestOject);
};

export const resetPassword = async (
  data: TResetPasswordBody
): Promise<void> => {
  const requestOject = {
    password: data.password,
    repeatedPassword: data.repeatedPassword,
    token: data.token,
  };
  return post(AUTH_ENDPOINTS.RESET_PASSWORD, requestOject);
};

export const requestResetPassword = async (
  data: TRequestResetPasswordBody
): Promise<void> => {
  const requestOject = {
    email: data.email,
  };
  return post(AUTH_ENDPOINTS.FORGET_PASSWORD, requestOject);
};
