import { ForgetPasswordForm } from "../ForgetPasswordForm";
import "./ForgetPasswordCard.styles.scss";

export const ForgetPasswordCard: React.FC = () => {
  return (
    <div className="nb-page-component-forget-password-card">
      <ForgetPasswordForm />
    </div>
  );
};
