import { get, post, put, remove } from "api";
import { COURSES_ENDPOINTS } from "api/endpoints/courses";
import {
  TAddCourseBody,
  TAddCourseLessonBody,
  TAddCourseLessonChapterBody,
  TAddCourseLessonToStudentBody,
  TAddCourseToStudentBody,
  TCourse,
  TCourseLesson,
  TCourseLessonChapter,
  TCourseLessonRequestParams,
  TCourseLessonResponse,
  TCourseRequestParams,
  TCourseResponse,
  TDetachCourseFromStudentBody,
  TDetachCourseLessonFromStudentBody,
  TEditCourseBody,
  TEditCourseLessonBody,
} from "types/api/course";
import { TUrlParam } from "types/utils";

export const getCourses = (
  options?: TCourseRequestParams
): Promise<TCourseResponse> => {
  const {
    pageNumber = 0,
    pageSize = 10,
    searchText = "",
    studentId,
  } = options || {};
  return get<TCourseResponse>(COURSES_ENDPOINTS.COURSES, {
    params: {
      pageNumber,
      pageSize,
      searchText,
      studentId,
    },
  });
};

export const getCourseById = (id: TUrlParam): Promise<TCourse> =>
  get<TCourse>(`${COURSES_ENDPOINTS.COURSES}/${id}`);

export const addCourse = (course: TAddCourseBody): Promise<TCourse> =>
  post<TCourse>(COURSES_ENDPOINTS.COURSES, course);

export const editCourse = (course: TEditCourseBody): Promise<TCourse> =>
  put<TCourse>(COURSES_ENDPOINTS.COURSES, course);

export const removeCourse = (id: number): Promise<void> =>
  remove<void>(`${COURSES_ENDPOINTS.COURSES}/${id}`);

export const getCourseLessons = (
  courseId?: string | number,
  options?: TCourseLessonRequestParams
): Promise<TCourseLessonResponse> => {
  const {
    pageNumber = 0,
    pageSize = 10,
    empty = true,
    sorted = false,
    unsorted = true,
    searchText = "",
    studentId,
  } = options || {};
  return get<TCourseLessonResponse>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons`,
    {
      params: {
        pageNumber,
        pageSize,
        empty,
        sorted,
        unsorted,
        searchText,
        studentId,
      },
    }
  );
};

export const getCourseLessonById = (
  courseId: TUrlParam,
  lessonId: TUrlParam
): Promise<TCourseLesson> =>
  get<TCourseLesson>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}`
  );

export const addCourseLesson = (
  courseId: TUrlParam,
  courseLesson: TAddCourseLessonBody
): Promise<TCourseLesson> =>
  post<TCourseLesson>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons`,
    courseLesson
  );

export const editCourseLesson = (
  courseId: TUrlParam,
  courseLesson: TEditCourseLessonBody
): Promise<TCourseLesson> =>
  put<TCourseLesson>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons`,
    courseLesson
  );

export const removeCourseLesson = (
  courseId: TUrlParam,
  lessonId: number
): Promise<void> =>
  remove<void>(`${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}`);

export const getCourseLessonChapters = (
  courseId: TUrlParam,
  lessonId: TUrlParam
): Promise<TCourseLessonChapter[]> =>
  get<TCourseLessonChapter[]>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/chapters`
  );

export const getCourseLessonChapterById = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam
): Promise<TCourseLessonChapter> =>
  get<TCourseLessonChapter>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/chapters/${chapterId}`
  );

export const addCourseLessonChapter = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  lessonChapter?: TAddCourseLessonChapterBody
): Promise<TCourseLessonChapter> =>
  post<TCourseLessonChapter>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/chapters`,
    lessonChapter
  );

export const editCourseLessonChapter = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  lessonChapter?: TCourseLessonChapter
): Promise<TCourseLessonChapter> =>
  put<TCourseLessonChapter>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/chapters`,
    lessonChapter
  );

export const removeCourseLessonChapter = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam
): Promise<void> =>
  remove<void>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/chapters/${chapterId}`
  );

export const addCourseToStudent = ({
  courseId,
  studentId,
}: TAddCourseToStudentBody): Promise<void> =>
  post<void>(`${COURSES_ENDPOINTS.COURSES}/${courseId}/add-to-student`, {
    studentId,
  });

export const addCourseLessonToStudent = ({
  courseId,
  lessonId,
  studentId,
}: TAddCourseLessonToStudentBody): Promise<void> =>
  post<void>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/add-to-student`,
    {
      studentId,
    }
  );

export const detachCourseLessonFromStudent = ({
  courseId,
  lessonId,
  studentId,
}: TDetachCourseLessonFromStudentBody): Promise<void> =>
  post<void>(
    `${COURSES_ENDPOINTS.COURSES}/${courseId}/lessons/${lessonId}/remove-from-student`,
    {
      studentId,
    }
  );

export const detachCourseFromStudent = ({
  courseId,
  studentId,
}: TDetachCourseFromStudentBody): Promise<void> =>
  post<void>(`${COURSES_ENDPOINTS.COURSES}/${courseId}/remove-from-student`, {
    studentId,
  });
