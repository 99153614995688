import { EMAIL_REGEX } from "constants/validation";
import { useRequestResetPassword } from "hooks/api/auth/useRequestResetPassword";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { Route } from "constants/routes";
import { LoginContent, ValidationContent } from "content";
import { logSuccess } from "utils/notifications";
import type { TRequestResetPasswordFormValues } from "./RequestResetPasswordForm.types";
import "./RequestResetPasswordForm.styles.scss";

export const RequestResetPasswordForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<TRequestResetPasswordFormValues>({
    mode: "onBlur",
  });
  const navigate = useNavigate();

  const { mutate: requestResetPassword } = useRequestResetPassword();

  const submitHandler = (formData: TRequestResetPasswordFormValues) => {
    const body = { ...formData };

    requestResetPassword(body, {
      onSuccess: () => {
        logSuccess(LoginContent.RequestResetPassword.Notification.SUCCESS);
        navigate(Route.Auth.Login.ROOT.path);
        reset();
      },
    });
  };

  return (
    <form
      className="nb-page-component-request-reset-password-form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Input<TRequestResetPasswordFormValues>
        id="request-reset-password"
        type="email"
        name="email"
        label={LoginContent.RequestResetPassword.Label.Email}
        placeholder={LoginContent.RequestResetPassword.Field.Email}
        register={register}
        rules={{
          pattern: {
            value: EMAIL_REGEX,
            message: ValidationContent.Pattern.Email,
          },
        }}
        errors={errors}
        required
        fullWidth
      />
      <Button type="submit" fullWidth disabled={!isValid}>
        {LoginContent.RequestResetPassword.Button.Submit}
      </Button>
    </form>
  );
};
