import { Space } from "components/Common/Space";
import { Divider } from "components/Common/Divider";
import { Image } from "components/Common/Image";
import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { Paragraph } from "components/Typography/Paragraph";
import {
  TDescribeImageExercise,
  TDescribeImageExercisePayload,
} from "types/app/exercises";
import "./DescribeImage.styles.scss";

type TDescribeImageProps = {
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TDescribeImageExercise;
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
};

export const DescribeImage: React.FC<TDescribeImageProps> = (props) => {
  const { chapterId, exercise, prefix = null } = props;

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      <ExerciseHeader<TDescribeImageExercisePayload>
        chapterId={chapterId}
        exercise={exercise}
        prefix={prefix}
      />
      {exercise.payload.data.images?.map((image, index) => (
        <Space
          key={image.id}
          direction="vertical"
          size="medium"
          justify="start"
          fullWidth
        >
          <Image
            src={image.imageUrl}
            alt="Describe image"
            className="nb-exercise-describe-image"
            fullWidth
            rounded
          />
          {image.description && (
            <Paragraph variant={2}>{image.description}</Paragraph>
          )}
          {self.length !== index + 1 && <Divider />}
        </Space>
      ))}
    </Space>
  );
};
