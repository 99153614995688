import { EStudentProgressQueryKeys } from "api/endpoints/student-progress";
import { getChapterStudentProgress } from "api/services/student-progress";
import { useQuery } from "react-query";
import { logError } from "utils/notifications";
import { TApiError } from "types/api/error";
import { TExercise } from "types/api/exercise";
import { TUrlParam } from "types/utils";

export const useGetChapterStudentProgress = (
  courseId: TUrlParam,
  lessonId: TUrlParam,
  chapterId: TUrlParam,
  studentId: TUrlParam
) => {
  return useQuery<TExercise[], TApiError>(
    [
      EStudentProgressQueryKeys.getChapterStudentProgress,
      courseId,
      lessonId,
      chapterId,
      studentId,
    ],
    () => getChapterStudentProgress(courseId, lessonId, chapterId, studentId),
    {
      onError: (error) => logError({ error }),
      retry: false,
      enabled: !!(courseId && lessonId && chapterId && studentId),
    }
  );
};
