import { ExerciseHeader } from "components/Common/ExerciseHeader";
import { useRole } from "hooks/common";
import { useLessonSettings } from "hooks/redux";
import { useMemo } from "react";
import { NoteExerciseContent } from "content";
import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation.svg";
import { Note } from "components/Common/Note";
import { Space } from "components/Common/Space";
import {
  ENoteExerciseType,
  TNoteExercise,
  TNoteExercisePayload,
} from "types/app/exercises";
import "./Notes.styles.scss";

type TNotesProps = {
  /**
   * Lesson chapter order number with the exercise sequence number to display before the exercise condition.
   * @default null
   */
  prefix?: string | null;
  /**
   * Chapter ID to which the exercise belongs.
   */
  chapterId: number;
  /**
   * An exercise raw data from API response.
   */
  exercise: TNoteExercise;
};

export const Notes: React.FC<TNotesProps> = (props) => {
  const { exercise, chapterId, prefix } = props;

  const { isUserAdmin } = useRole();
  const { viewMode } = useLessonSettings();

  const noteTitle: string = useMemo(
    () =>
      exercise.payload.data.noteType === ENoteExerciseType.TEACHER_NOTE
        ? NoteExerciseContent.Option.TEACHER_NOTE
        : NoteExerciseContent.Option.INFO,
    [exercise.payload.data.noteType]
  );

  const noteIcon: React.ReactElement = useMemo(
    () =>
      exercise.payload.data.noteType === ENoteExerciseType.TEACHER_NOTE ? (
        <ListIcon width={20} height={20} />
      ) : (
        <ExclamationIcon width={20} height={20} />
      ),
    [exercise.payload.data.noteType]
  );

  return (
    <Space direction="vertical" size="medium" justify="start" fullWidth>
      {viewMode === "constructor" && isUserAdmin && (
        <ExerciseHeader<TNoteExercisePayload>
          chapterId={chapterId}
          exercise={exercise}
          prefix={prefix}
        />
      )}
      <Note
        title={noteTitle}
        icon={noteIcon}
        content={exercise.payload.data.content}
        fullWidth
      />
    </Space>
  );
};
