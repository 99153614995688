import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCourseById,
  useCourseLessons,
  useRemoveCourseLesson,
  useUpdateCourseLessonOrder,
} from "hooks/api/courses";
import { useModal } from "hooks/common/useModal";
import { useBreadcrumbs } from "hooks/redux";
import { useCourseLessonColumns } from "hooks/table/useCourseLessonColumns";
import { CourseDetailsContent } from "content";
import { Table } from "components/Table";
import { Empty } from "components/Common/Empty";
import { ActionModal } from "components/Common/ActionModal";
import { CourseHero } from "./components/CourseHero";
import { EditCourseLessonModal } from "./components/EditCourseLessonModal";
import { MaterialsSearch } from "../../components/MaterialsSearch";
import { logError, logSuccess } from "utils/notifications";
import { Route } from "constants/routes";
import { TCourseLesson, TEditCourseLessonBody } from "types/api/course";
import { EMaterialsType } from "types/app/materials";
import { TSelectOption } from "types/app/select";
import { TEveryRequestParams } from "types/api/server";
import "./CourseDetails.styles.scss";

const PAGINATION_ENABLED = false;

export const CourseDetails: React.FC = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const { setPageName, resetPageName } = useBreadcrumbs();

  const [searchText, setSearchText] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<EMaterialsType>(
    EMaterialsType.CATALOG
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [selectedLessonId, setSelectedLessonId] = useState<number | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<TCourseLesson | null>(
    null
  );

  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const lessonRequestParams: TEveryRequestParams = useMemo(() => {
    const params: TEveryRequestParams = {
      pageNumber,
      searchText,
    };

    if (!PAGINATION_ENABLED) {
      params.pageSize = 99999;
    }

    return params;
  }, [pageNumber, searchText]);

  const {
    data: course,
    isLoading: isCourseLoading,
    error,
  } = useCourseById(courseId);
  const { data: courseLessonsData, isLoading } = useCourseLessons(
    courseId,
    lessonRequestParams
  );
  const { mutate: deleteCourseLesson } = useRemoveCourseLesson(courseId);
  const { mutate: editCourseLessonHandler } = useUpdateCourseLessonOrder(
    courseId,
    lessonRequestParams
  );

  const courseLessons: TCourseLesson[] = useMemo(
    () => courseLessonsData?.content || [],
    [courseLessonsData?.content]
  );
  const isEmpty: boolean = courseLessons.length === 0;

  useEffect(() => {
    if (course?.name) {
      setPageName(course.name);
    }
    return () => resetPageName();
  }, [course?.name, setPageName, resetPageName]);

  useEffect(() => {
    // TODO: handler properly not found resource error
    if (error) {
      navigate(Route.App.Materials.ROOT.path);
    }
  }, [error, navigate]);

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return courseLessons.map((lesson) => ({
      label: lesson.name,
      value: lesson.id,
    }));
  }, [courseLessons]);

  const tabChangeHandler = (activeTab: EMaterialsType) => {
    /**
     * TODO: Add additional logic on tab change (filter or fetch courses data)
     */
    setCurrentTab(activeTab);
  };

  const editButtonClickHandler = (lesson: TCourseLesson) => {
    setSelectedLesson(lesson);
    openEditModal();
  };

  const closeEditModalHandler = () => {
    setSelectedLesson(null);
    closeEditModal();
  };

  const deleteButtonClickHandler = (lessonId: number) => {
    setSelectedLessonId(lessonId);
    openDeleteModal();
  };

  const deleteSubmitHandler = () => {
    if (selectedLessonId === null) {
      logError({
        message: CourseDetailsContent.Delete.Lesson.Notification.ERROR,
      });
      return;
    }
    deleteCourseLesson(selectedLessonId, {
      onSuccess: () => {
        logSuccess(CourseDetailsContent.Delete.Lesson.Notification.SUCCESS);
        setPageNumber(0);
        setSelectedLessonId(null);
        closeDeleteModal();
      },
    });
  };

  const lessonOrderChangeHandler = (
    lessonId: number | string,
    newPosition: number
  ) => {
    const courseLesson = courseLessons.find((lesson) => lesson.id === lessonId);
    if (!courseLesson) {
      logError({
        message: CourseDetailsContent.Edit.Lesson.Notification.ERROR,
      });
      return;
    }

    const courseLessonBody: TEditCourseLessonBody = {
      ...courseLesson,
      order: newPosition,
    };

    editCourseLessonHandler(courseLessonBody);
  };

  const columns = useCourseLessonColumns(courseId, {
    onDeleteClick: deleteButtonClickHandler,
    onEditClick: editButtonClickHandler,
  });

  return (
    <>
      <MaterialsSearch
        currentTab={currentTab}
        onSearch={searchCallback}
        onSearchValueChange={setSearchText}
        onTabChange={tabChangeHandler}
        searchEmptyText={CourseDetailsContent.Search.NO_OPTIONS}
        searchPlaceholder={CourseDetailsContent.Search.PLACEHOLDER}
      />
      <CourseHero course={course} loading={isCourseLoading} />
      {isEmpty && !isLoading ? (
        <Empty text={CourseDetailsContent.Empty} />
      ) : (
        <Table<TCourseLesson>
          data={courseLessons}
          columns={columns}
          loading={isLoading}
          totalItems={courseLessonsData?.totalElements}
          onPageChange={setPageNumber}
          onDragComplete={lessonOrderChangeHandler}
          hideHeader
          hideFooter={!PAGINATION_ENABLED}
          allowRowReorder
        />
      )}
      <ActionModal
        text={CourseDetailsContent.Delete.Lesson.TITLE}
        emoji={CourseDetailsContent.Delete.Lesson.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={deleteSubmitHandler}
      />
      <EditCourseLessonModal
        courseLesson={selectedLesson}
        open={isEditModalOpen}
        onClose={closeEditModalHandler}
      />
    </>
  );
};
