import { Route } from "constants/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { format, isPast } from "date-fns";
import { nanoid } from "nanoid";
import cx from "classnames";
import { useStudentById } from "hooks/api/students";
import { useTeacherById } from "hooks/api/teachers";
import { useGroupById } from "hooks/api/groups";
import { useEditScheduleLesson } from "hooks/api/schedule";
import { useRole } from "hooks/common";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { Button } from "components/Interactive/Button";
import { Select } from "components/Interactive/Select";
import { DatePicker } from "components/Interactive/DatePicker";
import { ReactPortal } from "components/Common/ReactPortal";
import { useNavigate } from "react-router-dom";
import { DetailedDurationSelect } from "../DetailedDurationSelect";
import { ScheduleContent } from "content";
import {
  LESSON_DURATION_OPTIONS,
  LESSON_STATUS_OPTIONS,
  LESSON_TIME_OPTIONS,
} from "utils/options";
import { logError, logSuccess } from "utils/notifications";
import { TEditLessonBody, TLesson, TLessonDuration } from "types/api/lesson";
import { TSelectOption } from "types/app/select";
import {
  TEditEventTeacherDetails,
  TEditScheduleEventForm,
} from "./EditEventForm.types";
import "./EditEventForm.styles.scss";

type EditEventFormProps = {
  /**
   * Selected schedule event (lesson) which comes from `ScheduleDashboard`.
   */
  lesson?: TLesson;
  /**
   * Callback fired when `Submit` event ocurred.
   */
  onSubmit?: () => void;
  /**
   * Override or extend the styles applied to the component.
   */
  className?: string;
};

export const EditEventForm: React.FC<EditEventFormProps> = (props) => {
  const { lesson, onSubmit, className } = props;

  const navigate = useNavigate();
  const [innerStartDate, setInnerStartDate] = useState<Date>(new Date());
  const [innerEndDate, setInnerEndDate] = useState<Date>(new Date());
  const idRef = useRef<string>(nanoid());

  const { isUserStudent, isUserTeacher } = useRole();

  const { data: studentData } = useStudentById(lesson?.studentId ?? undefined);
  const { data: groupData } = useGroupById(lesson?.groupId ?? undefined);
  const { data: teacherData } = useTeacherById(lesson?.teacherId);
  const { mutate: editScheduleLesson } = useEditScheduleLesson();

  const isLessonFinished = useMemo(
    () => (lesson ? isPast(new Date(lesson.endDateTime)) : false),
    [lesson]
  );

  // Constructing options for selects
  const teacherDetails: TEditEventTeacherDetails = teacherData && {
    id: teacherData.id,
    name: `${teacherData.person.firstName} ${teacherData.person.lastName}`,
    description: teacherData.person.email,
  };

  // Making default option values
  const initialDurationOption = LESSON_DURATION_OPTIONS.find(
    (option) => option.value === lesson?.duration
  );
  const [firstTimeOption] = LESSON_TIME_OPTIONS;
  const [firstStatusOption] = LESSON_STATUS_OPTIONS;
  const timeOption = LESSON_TIME_OPTIONS.find(
    (time) => time.value === format(innerStartDate, "HH:mm")
  );
  const defaultValues: Partial<TEditScheduleEventForm> = useMemo(
    () => ({
      group: groupData?.name,
      student:
        studentData &&
        `${studentData.person.firstName} ${studentData.person.lastName}`,
      duration: initialDurationOption,
      time: timeOption || firstTimeOption,
      repeated: true,
      status: firstStatusOption,
    }),
    [
      groupData?.name,
      studentData,
      timeOption,
      initialDurationOption,
      firstTimeOption,
      firstStatusOption,
    ]
  );

  // Initiating form handler
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TEditScheduleEventForm>({
    mode: "onSubmit",
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Handling date update
  useEffect(() => {
    if (lesson) {
      const startDate = new Date(lesson.startDateTime);
      const endDate = new Date(lesson.endDateTime);
      setInnerStartDate(startDate);
      setInnerEndDate(endDate);
    }
  }, [lesson]);

  // Form handlers
  const editScheduleEventSubmitHandler = async (
    formData: TEditScheduleEventForm
  ) => {
    if (isUserStudent) {
      return;
    }

    if (!lesson) {
      logError({ message: ScheduleContent.Read.Notification.ERROR });
      return;
    }

    const lessonDate = format(innerStartDate, "MM/dd/yyyy");
    const lessonTime = `${formData.time.value}:00`;
    const lessonEvent: TEditLessonBody = {
      lesson: {
        id: lesson.id,
        teacherId: lesson.teacherId,
        type: lesson.type,
        duration: formData.duration.value,
        studentId: lesson.studentId,
        groupId: lesson.groupId,
        startDateTime: `${lessonDate} ${lessonTime}`,
        // ...(isLessonFinished && { status: formData.status.value }),
      },
    };

    editScheduleLesson(lessonEvent, {
      onSuccess: () => {
        onSubmit?.();
        logSuccess(ScheduleContent.Read.Notification.SUCCESS);
        reset();
      },
    });
  };

  const dateChangeHandler = (rangeStart: Date, rangeEnd: Date) => {
    setInnerStartDate(rangeStart);
    setInnerEndDate(rangeEnd);
  };

  const goToLessonClickHandler = () => {
    if (!lesson?.studentId) return;

    navigate(Route.App.Study.STUDENT_CLASSROOM.construct(lesson.studentId));
  };

  return (
    <>
      <form
        id={idRef.current}
        className={cx(["nb-page-component-edit-event-form", className])}
        onSubmit={handleSubmit(editScheduleEventSubmitHandler)}
      >
        <DetailedDurationSelect<
          TEditScheduleEventForm,
          TSelectOption<TLessonDuration>
        >
          name="duration"
          id="edit-event-duration"
          placeholder={ScheduleContent.Read.Input.Duration.PLACEHOLDER}
          details={teacherDetails}
          options={LESSON_DURATION_OPTIONS}
          control={control}
          errors={errors}
          readonly={isUserStudent || isUserTeacher}
        />
        <div className="nb-page-component-edit-event-date-wrapper">
          <DatePicker
            variant="input"
            className="nb-page-component-edit-event-date-picker"
            onChange={dateChangeHandler}
            label={ScheduleContent.Read.Input.Date.LABEL}
            value={[innerStartDate, innerEndDate]}
            mode="day"
            view="month"
            position="bottom-right"
            readonly={isUserStudent || isUserTeacher}
          />
          <Select<TEditScheduleEventForm, TSelectOption>
            name="time"
            id="edit-event-time"
            className="nb-page-component-edit-event-time"
            icon={<TimeIcon width={15} height={15} />}
            placeholder={ScheduleContent.Read.Input.Time.PLACEHOLDER}
            options={LESSON_TIME_OPTIONS}
            control={control}
            errors={errors}
            readonly={isUserStudent || isUserTeacher}
            required
            fullWidth
          />
        </div>
        {!isUserStudent && isLessonFinished && (
          <Select<TEditScheduleEventForm, TSelectOption>
            name="status"
            id="edit-event-status"
            className="nb-page-component-edit-event-status"
            label={ScheduleContent.Read.Input.Status.LABEL}
            placeholder={ScheduleContent.Read.Input.Status.PLACEHOLDER}
            options={LESSON_STATUS_OPTIONS}
            control={control}
            errors={errors}
            fullWidth
          />
        )}
        <ReactPortal wrapperId="edit-schedule-event-modal-footer">
          {(isUserStudent || isUserTeacher) && (
            <Button
              className="nb-page-component-edit-event-button"
              variant="primary"
              fullWidth
              onClick={goToLessonClickHandler}
            >
              {ScheduleContent.Read.Button.GO_TO_LESSON}
            </Button>
          )}
          {isLessonFinished && isUserTeacher && (
            <Button
              className="nb-page-component-edit-event-button"
              variant="primary"
              type="submit"
              form={idRef.current}
              fullWidth
            >
              {ScheduleContent.Read.Button.SUBMIT}
            </Button>
          )}
        </ReactPortal>
      </form>
    </>
  );
};
