import { useCallback, useMemo, useState } from "react";
import { useModal } from "hooks/common/useModal";
import { useRemoveStudent, useStudents } from "hooks/api/students";
import { useStudentColumns } from "hooks/table/useStudentColumns";
import { useRole } from "hooks/common";
import { useActiveUser } from "hooks/redux/useActiveUser";
import { Divider } from "components/Common/Divider";
import { Empty } from "components/Common/Empty";
import { Table } from "components/Table";
import { ActionModal } from "components/Common/ActionModal";
import { ContentModal } from "components/Common/ContentModal";
import { AddStudentForm } from "./components/AddStudentForm";
import { EditStudentForm } from "./components/EditStudentForm";
import { StudentScheduleModal } from "./components/StudentScheduleModal";
import { StudySearch } from "../../components/StudySearch";
import { StudentsContent } from "content";
import { logError, logSuccess } from "utils/notifications";
import { TStudent } from "types/api/student";
import { TSelectOption } from "types/app/select";
import { EStudySearchTarget } from "types/app/study";
import "./Students.styles.scss";

const PAGINATION_ENABLED = false;

export const Students: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null
  );
  const [selectedStudentProfileId, setSelectedStudentProfileId] = useState<
    number | null
  >(null);
  const [selectedStudent, setSelectedStudent] = useState<TStudent | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { isUserTeacher } = useRole();
  const { activeUser } = useActiveUser();

  const { data, isLoading } = useStudents({
    searchText: searchValue,
    pageNumber,
    pageSize: PAGINATION_ENABLED ? undefined : 99999,
    teacherId: isUserTeacher ? activeUser?.teacherId : null,
  });
  const { mutate } = useRemoveStudent();

  const students: TStudent[] = useMemo(
    () => data?.content || [],
    [data?.content]
  );
  const isEmpty: boolean = students.length === 0;

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);
  const {
    isModalOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModal(false);
  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);
  const {
    isModalOpen: isProfileModalOpen,
    closeModal: closeProfileModal,
    openModal: openProfileModal,
  } = useModal(false);

  const handleEditButtonClick = (student: TStudent) => {
    setSelectedStudent(student);
    openEditModal();
  };

  const handleDeleteButtonClick = (id: number) => {
    setSelectedStudentId(id);
    openDeleteModal();
  };

  const handleDeleteSubmit = () => {
    if (selectedStudentId === null) {
      logError({ message: StudentsContent.Delete.Notification.ERROR });
      return;
    }

    mutate(selectedStudentId, {
      onSuccess: () => {
        logSuccess(StudentsContent.Delete.Notification.SUCCESS);
        setPageNumber(0);
        closeDeleteModal();
      },
    });
  };

  const handleAddStudentSubmit = () => {
    closeAddModal();
  };

  const handleEditStudentSubmit = () => {
    closeEditModal();
  };

  const handleOpenStudentProfileSubmit = (studentId: number) => {
    if (isUserTeacher) {
      setSelectedStudentProfileId(studentId);
      openProfileModal();
    }
  };

  const handleeCloseStudentProfileSubmit = () => {
    setSelectedStudentProfileId(null);
    closeProfileModal();
  };

  const searchCallback = useCallback(async (): Promise<
    TSelectOption<number>[]
  > => {
    return students.map((student) => ({
      label: `${student.person.firstName} ${student.person.lastName}`,
      value: student.id,
    }));
  }, [students]);

  const columns = useStudentColumns({
    onEditClick: handleEditButtonClick,
    onDeleteClick: handleDeleteButtonClick,
    onStudentNameClick: handleOpenStudentProfileSubmit,
  });

  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Students}
        searchPlaceholder={StudentsContent.Search.PLACEHOLDER}
        searchCallback={searchCallback}
        setSearchValue={setSearchValue}
        onAddButtonClick={openAddModal}
      />
      <Divider margin="bottom" />
      {isEmpty && !isLoading ? (
        <Empty text={StudentsContent.Empty} />
      ) : (
        <Table<TStudent>
          data={students}
          columns={columns}
          loading={isLoading}
          totalItems={data?.totalElements}
          onPageChange={setPageNumber}
          hideFooter={!PAGINATION_ENABLED}
        />
      )}
      <ActionModal
        text={StudentsContent.Delete.TITLE}
        emoji={StudentsContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
      <ContentModal
        portalId="add-student-modal"
        footerId="add-student-modal-footer"
        open={isAddModalOpen}
        title={StudentsContent.Add.TITLE}
        emoji={StudentsContent.Add.EMOJI}
        onClose={closeAddModal}
      >
        <AddStudentForm onSubmit={handleAddStudentSubmit} />
      </ContentModal>
      <ContentModal
        portalId="edit-student-modal"
        footerId="edit-student-modal-footer"
        open={isEditModalOpen}
        title={StudentsContent.Edit.TITLE}
        emoji={StudentsContent.Edit.EMOJI}
        onClose={closeEditModal}
      >
        <EditStudentForm
          targetStudent={selectedStudent}
          onSubmit={handleEditStudentSubmit}
        />
      </ContentModal>
      <StudentScheduleModal
        studentId={selectedStudentProfileId}
        open={isProfileModalOpen}
        onClose={handleeCloseStudentProfileSubmit}
      />
    </>
  );
};
