import { useResetPassword } from "hooks/api/auth/useResetPassword";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Input } from "components/Interactive/Input";
import { Button } from "components/Interactive/Button";
import { Route } from "constants/routes";
import { LoginContent, UserSettingsContent } from "content";
import { logSuccess } from "utils/notifications";
import type { TForgetPasswordFormValues } from "./ForgetPasswordForm.types";
import "./ForgetPasswordForm.styles.scss";

export const ForgetPasswordForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<TForgetPasswordFormValues>({
    mode: "onBlur",
  });
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const { mutate: resetPassword } = useResetPassword();

  const submitHandler = (formData: TForgetPasswordFormValues) => {
    if (!token) return;

    const body = { ...formData, token };

    resetPassword(body, {
      onSuccess: () => {
        logSuccess(LoginContent.ForgetPassword.Notification.SUCCESS);
        navigate(Route.Auth.Login.ROOT.path);
        reset();
      },
    });
  };

  return (
    <form
      className="nb-page-component-forget-password-form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Input<TForgetPasswordFormValues>
        id="forget-password"
        type="password"
        name="password"
        className="nb-page-component-forget-password-email"
        label={LoginContent.ForgetPassword.Label.Password}
        placeholder={LoginContent.ForgetPassword.Field.Password}
        register={register}
        errors={errors}
        required
        autoFocus
        fullWidth
      />
      <Input<TForgetPasswordFormValues>
        id="forget-repeatedPassword"
        type="password"
        name="repeatedPassword"
        label={LoginContent.ForgetPassword.Label.RepeatedPassword}
        placeholder={LoginContent.ForgetPassword.Field.RepeatedPassword}
        register={register}
        rules={{
          validate: (value) =>
            value === getValues("password") ||
            UserSettingsContent.ChangePassword.Error.NOT_MATCH,
        }}
        errors={errors}
        required
        fullWidth
      />
      <Button type="submit" fullWidth disabled={!token}>
        {LoginContent.ForgetPassword.Button.Submit}
      </Button>
    </form>
  );
};
