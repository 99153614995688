import { useDroppable } from "@dnd-kit/core";
import cx from "classnames";
import { Space } from "components/Common/Space";
import { AttemptIndicator } from "components/Common/AttemptIndicator";
import { Tooltip } from "components/Interactive/Tooltip";
import { useRole } from "hooks/common";
import { TMoveWordsIntoGapsExerciseBlank } from "types/app/exercises";
import "./Droppable.styles.scss";

type TDroppableProps = {
  /**
   * Blank data to populate droppable component.
   */
  blank: TMoveWordsIntoGapsExerciseBlank;
  /**
   * If the draggable element that is currently being dragged, the `dragging` property will be `true`.
   * @default false
   */
  dragging?: boolean;
  /**
   * Indicates if a user is failed all attempts to pick the correct answer on this droppable blank.
   * @default false
   */
  failed?: boolean;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  attemptsUsed?: number;
  isSuccessful?: boolean;
};

export const Droppable: React.FC<TDroppableProps> = (props) => {
  const {
    children,
    blank,
    dragging = false,
    attemptsUsed = 0,
    isSuccessful = false,
  } = props;

  const { isUserStudent } = useRole();

  const { isOver, setNodeRef } = useDroppable({
    id: blank.id,
    data: { blank },
  });

  return (
    <Space
      direction="horizontal"
      justify="start"
      align="center"
      size="xx-small"
    >
      <Tooltip
        text={blank.hint}
        disabled={isUserStudent}
        trigger="hover"
        position="top-start"
        spaceSize="small"
        arrow="visible"
        containerClassName="nb-exercise-move-words-droppable-tooltip-container"
        className="nb-exercise-move-words-droppable-tooltip"
      >
        <div
          ref={setNodeRef}
          className={cx("nb-exercise-move-words-droppable", {
            "nb-exercise-move-words-droppable--over": isOver,
            "nb-exercise-move-words-droppable--dragging": dragging,
            "nb-exercise-move-words-droppable--dropped": children,
          })}
          aria-label="Поле в яке можна перенести відповідь"
        >
          {children}
        </div>
      </Tooltip>
      <AttemptIndicator
        attempts={attemptsUsed}
        maxAttempts={3}
        isSuccessful={isSuccessful}
      />
    </Space>
  );
};
