import { useGetHomeworkStudentProgress } from "hooks/api/student-progress";
import { useRole } from "hooks/common";
import { useIsClassroom } from "hooks/common/useIsClassroom/useIsClassroom";
import { useParams } from "react-router-dom";
import { useHomeworkExercises } from "hooks/api/exercises";
import { useExerciseConstructor, useLessonSettings } from "hooks/redux";
import { LessonDetailsContent } from "content";
import { Button } from "components/Interactive/Button";
import { Divider } from "components/Common/Divider";
import { ExercisesPreview } from "components/Lesson";
import "./HomeworkContentExercises.styles.scss";

type HomeworkContentExercisesProps = {
  /**
   * Unique identifier of the lesson homework entity.
   */
  homeworkId?: number;
};

export const HomeworkContentExercises: React.FC<
  HomeworkContentExercisesProps
> = (props) => {
  const { homeworkId } = props;

  const { courseId, lessonId, studentId } = useParams();
  const { isUserAdmin } = useRole();
  const { isClassroom } = useIsClassroom();

  const { data: exercisesData, isLoading: exercisesLoading } =
    useHomeworkExercises(
      !isClassroom ? courseId : null,
      !isClassroom ? lessonId : null,
      !isClassroom ? homeworkId : null
    );

  const { data: progressData, isLoading: progressLoading } =
    useGetHomeworkStudentProgress(
      isClassroom ? courseId : null,
      isClassroom ? lessonId : null,
      isClassroom ? homeworkId : null,
      isClassroom ? studentId : null
    );

  const homeworkExercises = isClassroom ? progressData : exercisesData;
  const isLoading = isClassroom ? progressLoading : exercisesLoading;

  const { openExerciseList } = useExerciseConstructor();
  const { viewMode } = useLessonSettings();

  const openExerciseConstructorClickHandler = () => {
    openExerciseList();
  };

  return (
    <div className="nb-lesson-details-homework-exercises-wrapper">
      <div className="nb-lesson-details-homework-exercises">
        <ExercisesPreview
          exercises={homeworkExercises}
          chapterId={1}
          loading={isLoading}
          isHomeworkExercises={true}
        />
        {viewMode === "constructor" && isUserAdmin && (
          <Button
            variant="secondary"
            fullWidth
            onClick={openExerciseConstructorClickHandler}
          >
            {LessonDetailsContent.Content.Homework.Button.ADD}
          </Button>
        )}
      </div>
      <Divider
        margin="yAxis"
        marginSize="large"
        className="nb-lesson-details-homework-exercises-divider"
      />
    </div>
  );
};
