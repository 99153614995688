import { useMemo, useState } from "react";
import { RequestsContent } from "content";
import { useModal } from "hooks/common";
import { useRemoveRequest, useRequests } from "hooks/api/requests";
import { useRequestColumns } from "hooks/table/useRequestColumns";
import { logError, logSuccess } from "utils/notifications";
import { Divider } from "components/Common/Divider";
import { Empty } from "components/Common/Empty";
import { ContentModal } from "components/Common/ContentModal";
import { ActionModal } from "components/Common/ActionModal";
import { Table } from "components/Table";
import { AddRequestForm } from "./components/AddRequestForm";
import { StudySearch } from "../../components/StudySearch";
import { EStudySearchTarget } from "types/app/study";
import { TRequest } from "types/api/requests";

const PAGINATION_ENABLED = false;

export const Requests: React.FC = () => {
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { data, isLoading } = useRequests({
    pageNumber: pageNumber,
    pageSize: PAGINATION_ENABLED ? undefined : 99999,
  });
  const { mutate: removeRequest } = useRemoveRequest();

  const {
    isModalOpen: isAddModalOpen,
    closeModal: closeAddModal,
    openModal: openAddModal,
  } = useModal(false);

  const {
    isModalOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModal(false);

  const requests: TRequest[] = useMemo(
    () =>
      (data?.content || []).filter(
        (request) =>
          request.name
            .trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          request.phone
            .trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          request.period
            ?.trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase()) ||
          request.studyMethod
            ?.trim()
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())
      ),
    [data?.content, searchValue]
  );
  const isEmpty: boolean = useMemo(() => requests.length === 0, [requests]);

  const addRequestSubmitHandler = () => {
    closeAddModal();
  };

  const handleDeleteSubmit = () => {
    if (selectedRequestId === null) {
      logError({ message: RequestsContent.Delete.Notification.ERROR });
      return;
    }

    removeRequest(selectedRequestId, {
      onSuccess: () => {
        logSuccess(RequestsContent.Delete.Notification.SUCCESS);
        closeDeleteModal();
      },
    });
  };

  const deleteButtonClickHandler = (id: number) => {
    setSelectedRequestId(id);
    openDeleteModal();
  };

  const columns = useRequestColumns({
    onDelete: deleteButtonClickHandler,
  });

  return (
    <>
      <StudySearch
        searchTarget={EStudySearchTarget.Requests}
        searchPlaceholder={RequestsContent.Search.PLACEHOLDER}
        setSearchValue={setSearchValue}
        onAddButtonClick={openAddModal}
      />
      <Divider margin="bottom" />
      {isEmpty && !isLoading ? (
        <Empty text={RequestsContent.Empty} />
      ) : (
        <Table<TRequest>
          data={requests}
          columns={columns}
          loading={isLoading}
          totalItems={data?.totalElements}
          onPageChange={setPageNumber}
          hideFooter={!PAGINATION_ENABLED}
        />
      )}
      <ContentModal
        portalId="add-request-modal"
        footerId="add-request-modal-footer"
        open={isAddModalOpen}
        title={RequestsContent.Add.TITLE}
        emoji={RequestsContent.Add.EMOJI}
        onClose={closeAddModal}
      >
        <AddRequestForm onSubmit={addRequestSubmitHandler} />
      </ContentModal>
      <ActionModal
        text={RequestsContent.Delete.TITLE}
        emoji={RequestsContent.Delete.EMOJI}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleDeleteSubmit}
      />
    </>
  );
};
