import { useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import cx from "classnames";
import { Loader } from "components/Common/Loader";
import { TableHead } from "../TableHead";
import { TableBody } from "../TableBody";
import { TableFooter } from "../TableFooter";
import { TableDragOverlay } from "../TableDragOverlay";
import { getRowSequenceNumber } from "utils/table";
import { updateTableDataOrder } from "../Table.utils";
import { Table } from "../Table.constants";
import type {
  TDefaultTableData,
  TTableClassNames,
  TTableRowIdentifier,
} from "../Table.types";
import "./TableRoot.styles.scss";

type TableRootProps<TData extends TDefaultTableData = TDefaultTableData> = {
  /**
   * The core data array for the table.
   */
  data: TData[];
  /**
   * Fallback for your data.
   */
  defaultData?: TData[];
  /**
   * Table columns definition for proper data display
   *
   * See documents for more
   * @link https://tanstack.com/table/v8/docs/guide/column-defs
   */
  columns: ColumnDef<TData, string>[];
  /**
   * Current page value of table pagination
   *
   * @default 0
   */
  pageIndex?: number;
  /**
   * Table pae size. Represents number of body rows per page
   *
   * @default 10
   */
  pageSize?: number;
  /**
   * If `true` component will display loader with animation.
   *
   * @default false
   */
  loading?: boolean;
  /**
   * If `true` the `Table` header component will be hidden.
   *
   * @default false
   */
  hideHeader?: boolean;
  /**
   * If `true` the `Table` footer component will be hidden.
   *
   * @default false
   */
  hideFooter?: boolean;
  /**
   * If `true` the `Table` rows can be reordered by drag'n'drop mechanics
   * and can be handled with `onDragComplete` event listener.
   *
   * @default false
   */
  allowRowReorder?: boolean;
  /**
   * The total number of rows.
   */
  totalItems?: number;
  /**
   * Callback fired when the page is changed.
   *
   * @param {Number} page - new page index of the `Table` component.
   * @return {undefined} should be voided function.
   */
  onPageChange?: (newPage: number) => void;
  /**
   * Fires after a draggable row is dropped.
   *
   * @param rowId id of the row that was dropped.
   * @param newPosition new order position of the row that was dropped (new index + 1).
   * @param oldPosition previous order position of the row that was dropped (old index + 1).
   */
  onDragComplete?: (
    rowId: Exclude<TTableRowIdentifier, null>,
    oldIndex: number,
    newIndex: number
  ) => void;
  /**
   * Object for `Table` sub-components to override or extend the styles applied to the particular component.
   */
  className?: TTableClassNames;
};

/**
 * Table component based on `react-table` library.
 * Also the table component uses drag'n'drop functionality with `@dnd-kit` package.
 * Current state is for basic usage, without grouping, resizing and other things.
 * We can expand functionality in this directory.
 *
 * @link https://tanstack.com/table/v8
 * @link https://docs.dndkit.com/
 */
export const TableRoot = <TData extends TDefaultTableData = TDefaultTableData>(
  props: TableRootProps<TData>
): JSX.Element => {
  const {
    className,
    columns,
    data,
    defaultData = [],
    pageIndex: userDefinedPageIndex = Table.DEFAULT.PAGE_INDEX,
    pageSize: userDefinedPageSize = Table.DEFAULT.PAGE_SIZE,
    loading = false,
    hideHeader = false,
    hideFooter = false,
    allowRowReorder = false,
    totalItems,
    onPageChange,
    onDragComplete,
  } = props;

  const [innerData, setInnerData] = useState<TData[]>(defaultData);
  const [dragActiveId, setDragActiveId] = useState<TTableRowIdentifier | null>(
    null
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: userDefinedPageIndex,
    pageSize: userDefinedPageSize,
  });
  const totalTableRows = totalItems || innerData.length;

  useEffect(() => {
    setInnerData(data);
  }, [data]);

  const items: TTableRowIdentifier[] = useMemo(
    () => innerData.map(({ id }) => id),
    [innerData]
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable<TData>({
    data: innerData,
    columns,
    pageCount: Math.ceil(totalTableRows / pageSize) ?? -1,
    state: {
      pagination,
    },
    defaultColumn: {
      size: Table.DEFAULT.WIDTH_HACK,
      minSize: Table.DEFAULT.MIN_WIDTH_HACK,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel<TData>(),
    manualPagination: true,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const rowDragStartHandler = (event: DragStartEvent) => {
    setDragActiveId(event.active.id);
  };

  const rowDragEndHandler = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldPosition = getRowSequenceNumber(
        items.indexOf(active.id),
        pagination
      );
      const newPosition = getRowSequenceNumber(
        items.indexOf(over.id),
        pagination
      );
      setInnerData((prevData) =>
        updateTableDataOrder<TData>(
          prevData,
          active.id,
          newPosition,
          oldPosition
        )
      );
      onDragComplete?.(active.id, newPosition, oldPosition);
    }
    setDragActiveId(null);
  };

  const rowDragCancelHandler = () => {
    setDragActiveId(null);
  };

  return (
    <div
      className={cx([
        "nb-table-wrapper",
        { "nb-table-wrapper--loading": loading },
        className?.wrapper,
      ])}
    >
      {loading ? (
        <Loader />
      ) : (
        <DndContext
          sensors={sensors}
          onDragEnd={rowDragEndHandler}
          onDragStart={rowDragStartHandler}
          onDragCancel={rowDragCancelHandler}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <table className={cx(["nb-table-root", className?.root])}>
            {!hideHeader && (
              <TableHead<TData>
                headerGroup={table.getHeaderGroups()}
                className={className?.head}
              />
            )}
            <TableBody<TData>
              items={items}
              rows={table.getRowModel().rows}
              allowRowReorder={allowRowReorder}
              className={className?.body}
            />
          </table>
          <TableDragOverlay<TData>
            allowRowReorder={allowRowReorder}
            dragActiveId={dragActiveId}
            rows={table.getRowModel().flatRows}
            className={className}
          />
          {totalTableRows > pagination.pageSize && !hideFooter && (
            <TableFooter<TData>
              table={table}
              className={className?.footer}
              onPageChange={onPageChange}
            />
          )}
        </DndContext>
      )}
    </div>
  );
};
